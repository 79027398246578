import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { toggleFollowUp, untoggleFollowUp } from 'store/modules/followUpState';
import { expandMiniWidget } from 'store/modules/expandedMiniWidgets';
import ContentLoader from 'components/loaders/ContentLoader';
import { InfoTooltip } from 'components/v3';

import chartStyles from './../chart/chart.scss';
import Chart, { isNumberChart, isTableChart } from '../chart/Chart';
import SmartActionButton from '../buttons/SmartActionButton';
import generalStyles from 'styles/global/general.scss';
import Button from 'components/buttons/Button';

import useMediaQuery from 'utils/mediaQueries';
import { createResizeObserver } from '../../utils/domObservers';
import ChangeVisualizationButton from 'components/buttons/ChangeVisualizationButton';
import { isInverted } from '../../config/constants';
import InvertButton from '../message/InvertButton';

import styles from './mini-board.scss';
import { adaptVisualizations } from '../../utils/chartUtils';
import { cancelWidget } from 'store/modules/board-widgets';

const findChartIndex = (visualizations, type) => {
  let initialIndex = visualizations?.findIndex(viz => viz.type === type);
  if (initialIndex === -1 || initialIndex === undefined) {
    initialIndex = 0;
  }
  return initialIndex;
};

const FollowUpButton = memo(({ answer, answerId, messageId, isFollowUp, dispatch, t }) => (
  <InfoTooltip text={isFollowUp ? t('tooltip.unfollow-up') : t('tooltip.follow-up')}>
    <button
      className={classnames('button-secondary', styles.followUp)}
      data-test="widgetFollowUpButton"
      onClick={() =>
        isFollowUp
          ? dispatch(untoggleFollowUp())
          : dispatch(toggleFollowUp(answer.interpretationId, answer.partialAnswerId, answerId, messageId))
      }
    >
      <span className="icon-follow-up" />
      FollowUp
    </button>
  </InfoTooltip>
));

const ExpandButton = memo(({ messageId, widgetId, answer, state, title, dispatch, t }) => (
  <InfoTooltip text={t('tooltip.expand')}>
    <button
      className="button-secondary"
      onClick={() => {
        dispatch(
          expandMiniWidget(messageId, widgetId, answer, state.activeChartIndex, state.isChartInverted, title, true)
        );
      }}
    >
      <span className="icon-expand" />
      {t('expand')}
    </button>
  </InfoTooltip>
));

const UnderstoodButton = memo(({ answer, t }) => {
  if (answer?.understood) {
    return (
      <InfoTooltip text={answer.understood}>
        <span className="button-secondary">
          <span className="icon-question" />
          {t('explanation')}
        </span>
      </InfoTooltip>
    );
  }
  return null;
});

const SmartActionButtons = memo(({ actions, dispatch }) => {
  if (!actions) return null;

  return actions.map((action, idx) => (
    <SmartActionButton action={action} iconClassName="icon-bolt-highlighted" dispatch={dispatch} key={idx} />
  ));
});

const ButtonBar = ({
  answer,
  answerId,
  actions,
  messageId,
  widgetId,
  isFollowUp,
  isEditing,
  dispatch,
  state,
  title,
  previewMode,
  t
}) => {
  return (
    <>
      <div className={styles.buttonBar}>
        {!previewMode && !isEditing && (
          <ExpandButton
            messageId={messageId}
            widgetId={widgetId}
            answer={answer}
            state={state}
            title={title}
            dispatch={dispatch}
            t={t}
          />
        )}
        {!previewMode && !isEditing && (
          <FollowUpButton
            answer={answer}
            answerId={answerId}
            messageId={messageId}
            isFollowUp={isFollowUp}
            dispatch={dispatch}
            data-test="followUpButton"
            t={t}
          />
        )}
        <UnderstoodButton answer={answer} t={t} />
        {!previewMode && !isEditing && <SmartActionButtons actions={actions} dispatch={dispatch} />}
      </div>
    </>
  );
};

const ChatBoardWidget = ({
  answer,
  initialVisualizationType,
  customVisualizationOptions,
  messageId,
  title,
  followUpState,
  answerId,
  isEditing,
  height,
  width,
  onWidgetDelete,
  onWidgetTitleChange,
  onWidgetVisualizationChange,
  id,
  hasFailed,
  errorMessage,
  isUnauthorized,
  previewMode,
  dispatch,
  t
}) => {
  const isInitiallyInverted = isInverted(initialVisualizationType);
  const initialChartIndex = findChartIndex(
    adaptVisualizations(answer?.visualizations, isInitiallyInverted),
    initialVisualizationType
  );
  const initialState = useMemo(
    () => ({
      activeChartIndex: initialChartIndex,
      isHover: false,
      isTitleEditing: false,
      isChartInverted: isInitiallyInverted
    }),
    [answer, initialVisualizationType]
  );

  const [state, setState] = useState(initialState);

  const adaptededVisualizations = useMemo(() => {
    return adaptVisualizations(answer?.visualizations, state.isChartInverted);
  }, [answer?.visualizations, state.isChartInverted]);

  const isMobile = useMediaQuery();

  const actions = useMemo(() => answer?.actions, [answer?.actions]);
  const isLoading = useMemo(() => !answer && !hasFailed, [answer, hasFailed]);

  const widgetNameRef = useRef(null);

  const onMouseEnter = () => setState(prevState => ({ ...prevState, isHover: true }));
  const onMouseLeave = () => setState(prevState => ({ ...prevState, isHover: false }));

  const onTitleClick = () => setState(prevState => ({ ...prevState, isTitleEditing: isEditing }));

  const onInvertButtonClick = () =>
    setState(prevState => ({ ...prevState, isChartInverted: !prevState.isChartInverted }));

  const updateCurrentTitle = () => {
    const newTitle = widgetNameRef.current && widgetNameRef.current.value;
    const isValidNewTitle = newTitle !== undefined && newTitle !== null && newTitle.trim() !== '';
    if (isValidNewTitle) {
      setState(prevState => ({ ...prevState, isTitleEditing: false }));
      // Only propagate if it actually changed
      newTitle !== title && onWidgetTitleChange(id, newTitle);
    }
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      updateCurrentTitle();
    }
  };

  const chooseVisualizationType = type => {
    onWidgetVisualizationChange(id, type);
    setState(prevState => ({ ...prevState, activeChartIndex: findChartIndex(adaptededVisualizations, type) }));
  };

  const currentVisualization = useMemo(() => adaptededVisualizations[state.activeChartIndex], [
    adaptededVisualizations,
    state.activeChartIndex
  ]);

  const chatBoardContainerStyle = useMemo(() => {
    if (hasFailed) {
      // for failed widgets we want to show the error message w/o any special styling
      // it could be that we haveFailed and still have e.g. concreteAnswer.type = table which would mess with error message display
      return styles.chatBoardChartContainer;
    } else if (isTableChart(currentVisualization?.type)) {
      return styles.miniTableContainer; // tables need some specific css
    } else if (isNumberChart(currentVisualization?.type)) {
      return styles.numberChartContainer; // number charts don't need to take up too much space, limit height.
    } else {
      return styles.chatBoardChartContainer; // default style with no upper-bound height
    }
  }, [currentVisualization, hasFailed]);

  const isFollowUp = useMemo(() => {
    return Boolean(
      followUpState &&
        answer &&
        messageId === followUpState.messageId &&
        answerId === followUpState.answerId &&
        answer.interpretationId === followUpState.interpretationId
    );
  }, [followUpState, answer, messageId, answerId]);

  const widgetClassNames = useMemo(
    () =>
      classnames(styles.miniWidget, styles.miniWidgetBorder, {
        [styles.followUp]: isFollowUp
      }),
    [isFollowUp]
  );

  // used to determine how much space the widget title component takes up, to optimize the number of rows shown
  // the title might be on two or more lines if the widget smaller
  // the initial state is important, since it will be initially rendered for a split-second
  const [titleHeight, setTitleHeight] = useState(34.5);
  const widgetTitleRef = useRef(null);
  useEffect(() => {
    const resizeObserver = createResizeObserver(rect => {
      // only update if the height has not changed to 0 (which could happen if we go in the visual mode and the title is temporarily hidden)
      if (rect.height > 0) {
        setTitleHeight(rect.height);
      }
    });
    if (widgetTitleRef.current) {
      resizeObserver.observe(widgetTitleRef.current);
    }
    return () => resizeObserver.disconnect();
  }, []);

  const handleCancelWidget = () => {
    if (id) {
      dispatch(cancelWidget(id));
    }
  };

  const chart = useMemo(() => {
    if (hasFailed) {
      const key = isUnauthorized ? 'widget-unauthorized' : 'widget-load-failed';
      const text = errorMessage ? `${t(key)}: ${errorMessage}` : t(key);
      return <div className={chartStyles.numberChartCentered}>{text}</div>;
    }
    if (!answer) return <ContentLoader onCancel={handleCancelWidget} />;

    if (answer.visualizations.length > 0) {
      return (
        <>
          {currentVisualization?.allowInvert && (
            <InvertButton
              className={styles.invertButton}
              isInverted={state.isChartInverted}
              onClick={onInvertButtonClick}
            />
          )}
          <Chart
            key={id + state.activeChartIndex + '_' + height + '_' + width} // id together with the visualisation and size identifies a chart uniquely atm. (on size change rerender)
            isCentered
            isInAnswerMessage={false}
            type={currentVisualization.type}
            visualizationOptions={currentVisualization.visualizationOptions}
            customVisualizationOptions={
              currentVisualization.type === initialVisualizationType ? customVisualizationOptions : undefined
            }
            chart={currentVisualization.chart}
            height={height}
            nonce={currentVisualization.nonce}
            answer={answer}
            isTableInWidget={true} // if Chart is TablePlot then display dynamic version based on height
            id={answerId + id}
            titleHeight={titleHeight}
          />
        </>
      );
    }
    return <div className={chartStyles.numberChartCentered}>{t('no-data-found')}</div>;
  }, [
    answer?.visualizations,
    currentVisualization,
    titleHeight,
    id,
    state.activeChartIndex,
    answerId,
    hasFailed,
    initialVisualizationType,
    height,
    width
  ]);

  useEffect(() => {
    const newIndex = findChartIndex(adaptededVisualizations, initialVisualizationType);
    if (answer && newIndex !== state.activeChartIndex) {
      setState(prevState => ({ ...prevState, activeChartIndex: newIndex }));
    }
  }, [answer, adaptededVisualizations, initialVisualizationType]);

  const widgetTitle = useMemo(() => {
    return !previewMode && isEditing && state.isTitleEditing ? (
      <input
        ref={widgetNameRef}
        defaultValue={title}
        data-css-selector="cancel-draggable"
        onKeyPress={handleKeyPress}
        className={styles.titleInput}
        onBlur={updateCurrentTitle} // todo: on highcharts click it doesn't auto blur
        id="BoardWidgetTitleInputHook"
        autoFocus
      />
    ) : (
      <div
        onClick={!previewMode ? () => onTitleClick() : () => {}}
        className={isEditing ? generalStyles.underlineOnHover : undefined}
      >
        <span data-test="chatBoardWidgetTitle" data-css-selector="cancel-draggable">
          {title}
        </span>
      </div>
    );
  }, [isEditing, state.isTitleEditing, title]);

  const deleteButton = () => {
    return (
      <>
        <Button
          onClick={() => onWidgetDelete(id)}
          className={`${'button-secondary'} ${styles.removeBoard}`}
          titleClassName="invisibleTitle"
          data-test="removeWidgetButton"
          title={t('delete')}
        >
          <span className="icon-close" />
        </Button>
      </>
    );
  };

  const boardEditButtons = (isEditing, shouldShow) => (
    <div
      style={{ position: 'absolute', right: 0, top: 3, display: shouldShow ? 'block' : 'none' }}
      data-css-selector="cancel-draggable"
    >
      {adaptededVisualizations?.length > 1 && (
        <ChangeVisualizationButton
          changeToVisualizationType={chooseVisualizationType}
          visualizationTypes={adaptededVisualizations.map(viz => viz.type)}
          currentVisualizationType={currentVisualization.type}
          hideButtonText={true}
          hideTableCharts={false}
          showModifyOption={false}
        />
      )}
      {isEditing && deleteButton()}
    </div>
  );

  return (
    <>
      <div
        className={styles.miniWidgetContainer}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        // we need an onClick event listener so we can get the menu on mobile
        onClick={onMouseEnter}
        // these attributes are here s.t. on follow up click we know what it refers to.
        data-interpretation-id={answer?.interpretationId}
        data-answer-id={answerId}
        data-test={isLoading ? 'chatBoardWidgetLoading' : 'chatBoardWidget'}
      >
        <div className={widgetClassNames}>
          <div className={styles.title} ref={widgetTitleRef}>
            {widgetTitle}
            {!previewMode &&
              !state.isTitleEditing &&
              boardEditButtons(isEditing, isMobile || state.isHover || isEditing)}
          </div>
          <div className={chatBoardContainerStyle}>{chart}</div>
          {(isMobile || state.isHover) && answer ? (
            <ButtonBar
              answer={answer}
              answerId={answerId}
              widgetId={id}
              actions={actions}
              messageId={messageId}
              isFollowUp={isFollowUp}
              isEditing={isEditing}
              dispatch={dispatch}
              state={state}
              title={title}
              previewMode={previewMode}
              t={t}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  followUpState: state.followUpState
});

export default withTranslation('veezoo')(connect(mapStateToProps)(memo(ChatBoardWidget)));
