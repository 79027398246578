import { cloneElement, forwardRef, useMemo, memo } from 'react';
import PropTypes from 'prop-types';

import UIButton from '@mui/material/Button';

import ArrowDownIcon from 'svg/v2/arrow_down.svg';

import { layouts } from 'config/constants';
import { useStyles, useTssStyles } from './Button.styles';

const iconPositions = { left: 'left', right: 'right' };

const Button = forwardRef(
  (
    {
      color,
      children = 'button',
      classes: outerClasses = {}, // The classes prop is used to override the default styles of the button.
      layout = layouts.default,
      onClick = () => {},
      size = 'default',
      mode = 'default',
      variant = 'text',
      disabled = false,
      fullWidth = false,
      iconOnly = false,
      reducedWidth = false,
      iconSize = '15px',
      noBorders = false,
      showArrow = false,
      secondaryColor = false,
      icon: Icon,
      iconPosition = iconPositions.left,
      ...rest
    },
    ref
  ) => {
    const buttonClasses = useStyles({
      fullWidth,
      size,
      mode,
      iconOnly,
      reducedWidth,
      iconSize,
      noBorders,
      showArrow,
      secondaryColor,
      outerClasses
    });

    const { classes: tssClasses } = useTssStyles({ iconSize, mode, secondaryColor, layout, outerClasses });

    const hasIcon = Icon ? cloneElement(Icon, { className: tssClasses.icon }) : null;

    const hasArrow = useMemo(() => showArrow && <ArrowDownIcon className={tssClasses.arrowIcon} />, [showArrow]);

    const customProps = useMemo(() => {
      if (layout === layouts.signup) return {};
      if (layout === layouts.veezoo) return {};
      return { variant, color };
    }, [layout, variant, color]);

    return (
      <UIButton sx={buttonClasses[layout]} ref={ref} onClick={onClick} disabled={disabled} {...customProps} {...rest}>
        {iconPosition == iconPositions.left && hasIcon}
        {!iconOnly && <span className={hasIcon && tssClasses.labelSpan}>{children}</span>}
        {iconPosition == iconPositions.right && hasIcon}
        {hasArrow}
      </UIButton>
    );
  }
);

Button.propTypes = {
  children: PropTypes.string,
  classes: PropTypes.object,
  fullWidth: PropTypes.bool,
  layout: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  mode: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  noBorders: PropTypes.bool,
  icon: PropTypes.any,
  iconOnly: PropTypes.bool,
  reducedWidth: PropTypes.bool,
  color: PropTypes.string,
  iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconPosition: PropTypes.string,
  showArrow: PropTypes.bool,
  secondaryColor: PropTypes.bool
};

export default memo(Button);
