import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Button, Checkbox, TagsContainer, TextField } from 'components/v3';

import LoadingIcon from 'svg/v2/loading.svg';

import useDebounce from 'utils/debounce';

import { layouts } from 'config/constants';
import styles from './MultiselectList.styles.scss';

const MultiselectList = ({
  options,
  selectedOptions,
  onSelectedChanged,
  onConfirm,
  onClose,
  onScrollToBottom,
  asyncSearch,
  hasPredefinedSelectedOptions,
  confirmButtonText,
  cancelButtonText,
  allowEmptySelection, // allows to confirm on an empty selection
  showLimitTags,
  loading,
  itemDataTest,
  t
}) => {
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState([]);

  const textInputRef = useRef(null);
  const optionListRef = useRef(null);

  const debouncedSearchTerm = useDebounce(search, 500);

  const loadMoreOptions = useCallback(() => {
    const container = optionListRef.current;
    if (container.scrollHeight - container.scrollTop - 1 <= container.clientHeight) {
      onScrollToBottom();
    }
  }, [optionListRef?.current, onScrollToBottom]);

  const handleAsyncSearch = async searchTerm => {
    if (!asyncSearch) return;
    await asyncSearch(searchTerm);
    textInputRef.current.focus();
  };

  const checkItem = entry => {
    let updatedSelected = [];

    if (selected.some(item => item.value === entry.value)) {
      updatedSelected = selected.filter(item => item.value !== entry.value);
    } else {
      updatedSelected = [...selected, entry];
    }
    setSelected(updatedSelected);
    onSelectedChanged(entry, updatedSelected);
  };

  useEffect(() => {
    if (onScrollToBottom) {
      optionListRef.current.addEventListener('scroll', loadMoreOptions);
      return () => optionListRef.current.removeEventListener('scroll', loadMoreOptions);
    }
  }, [onScrollToBottom, optionListRef, loadMoreOptions]);

  useEffect(() => {
    setSelected(selectedOptions);
  }, []);

  useEffect(() => {
    if (!hasPredefinedSelectedOptions) {
      setSelected(selectedOptions);
    }
  }, [selectedOptions, hasPredefinedSelectedOptions]);

  useEffect(() => {
    const newSearch = debouncedSearchTerm || '';
    handleAsyncSearch(newSearch);
  }, [debouncedSearchTerm]);

  const filteredOptions = asyncSearch
    ? options
    : options.filter(option =>
        option.text
          .toLowerCase()
          .trim()
          .includes(search.toLowerCase().trim())
      );

  const disableButtons = useMemo(() => loading || (selected.length < 1 && !allowEmptySelection), [loading, selected]);

  return (
    <div className={styles.container} data-testclass="multiSelectList">
      <div className={styles.input}>
        <TextField
          layout={layouts.clear}
          customClasses={{
            'MuiInputBase-root': {
              borderBottom: 'solid 2px var(--primary-light-color)'
            },
            'MuiInputBase-input': {
              fontSize: '14px',
              padding: '6px 0 7px'
            },
            'MuiInput-underline': {
              '&:before': {
                borderBottom: 'none'
              },
              '&:hover:before': {
                borderBottom: 'none !important'
              },
              '&:after': {
                borderBottom: 'none'
              }
            }
          }}
          title={t('search')}
          onChange={event => setSearch(event.target.value)}
          autoFocus
          ref={textInputRef}
          disabled={loading}
          placeholder={`${t('search')}...`}
          endIcon={
            <div className={styles.searchIcon}>
              <span className="icon-search" />
            </div>
          }
        />
      </div>

      {showLimitTags && (
        <TagsContainer
          items={selected.map(item => ({ ...item, label: item.text }))}
          onDeleteClick={item => checkItem(item)}
        />
      )}

      <div className={styles.listContainer}>
        {loading && (
          <div className={styles.loadingContainer}>
            <LoadingIcon className={styles.loadingIcon} />
          </div>
        )}
        <div className={styles.list} ref={optionListRef}>
          {filteredOptions.length ? (
            filteredOptions.map((option, index) => (
              <Checkbox
                layout={layouts.veezoo}
                data-testclass="multiSelectListItem"
                key={`check_${index}`}
                disabled={option.disabled || loading}
                content={option.text}
                checked={selected.some(item => item.value === option.value)}
                onChange={() => checkItem(option)}
                data-test={itemDataTest}
                classes={option.classes}
                disableRipple
                size="small"
              />
            ))
          ) : (
            <div className={styles.noItemsFound}>{t('no-data-found')}</div>
          )}
        </div>
      </div>
      <div className={clsx(styles.section, styles.flexContainer)}>
        <div className={clsx(styles.verticalPadding8, styles.flexGrow1)}>
          <Button layout={layouts.veezoo} fullWidth onClick={onClose} data-testclass="multiSelectListCancel">
            {cancelButtonText}
          </Button>
        </div>
        <div className={clsx(styles.verticalPadding8, styles.flexGrow1)}>
          <Button
            layout={layouts.veezoo}
            mode="dark"
            fullWidth
            disabled={disableButtons}
            onClick={() => onConfirm(selected)}
            data-testclass="multiSelectListOk"
          >
            {confirmButtonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withTranslation('veezoo')(connect()(MultiselectList));

MultiselectList.defaultProps = {
  confirmButtonText: 'Ask', // Need to get translated
  cancelButtonText: 'Cancel', // Need to get translated
  onSelectedChanged: () => {},
  showLimitTags: false,
  allowEmptySelection: false,
  selectedOptions: []
};
