export default {
  closeButton: {
    width: '32px',
    height: '32px',
    marginLeft: '5px',
    padding: 0,
    cursor: 'pointer',
    '&:hover': { '& path': { fill: '#f44336' } }
  }
};
