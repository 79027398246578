import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import styles from './user-message.scss';
import { showOrHideMessage } from 'store/modules/chat-messages';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { Button } from 'components/v3';

import { layouts } from 'config/constants';

export function HiddenMessage({ dispatch, hiddenMessageIds, t }) {
  const answerMessageIds = hiddenMessageIds?.answerMessageIds;

  return (
    <div className={styles.wrapper}>
      <div className={styles.hiddenMessageContainer}>
        <Button
          layout={layouts.veezoo}
          classes={{ root: { border: 'solid 1px var(--border-color)' } }}
          iconSize="20px"
          onClick={() => {
            [...hiddenMessageIds.answerMessageIds, ...hiddenMessageIds.localMessageIds].forEach(id =>
              dispatch(showOrHideMessage(false, id))
            );
          }}
          icon={<VisibilityIcon />}
        >
          {answerMessageIds.length > 1
            ? t('hide.unhide-answers', { count: answerMessageIds.length })
            : t('hide.unhide-answer')}
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({ chatMessages: state.chatMessages });

export default withTranslation('veezoo', { withRef: true })(connect(mapStateToProps)(HiddenMessage));
