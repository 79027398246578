import React, { memo, useCallback, useRef, useState } from 'react';

import services from 'services';
import Title from 'components/v2/Typography/Title';
import { withSnackbar } from 'notistack';
import { trackEvent } from 'utils/eventTracking';

import { Button, TextArea, TextField } from 'components/v3';

import { layouts } from 'config/constants';
import useStyles from './styles';

/**
 * Form for saving new rephrase ground truth.
 */
const SaveRephraseGroundTruthForm = ({ originalQuestion, rephrasedQuestion, onClose, t, enqueueSnackbar }) => {
  const styles = useStyles();

  const originalQuestionInputRef = useRef(null);
  const rephrasedInputRef = useRef(null);
  const explanationInputRef = useRef(null);

  const [fields, setFields] = useState({
    originalQuestion: originalQuestion,
    rephrasedQuestion: rephrasedQuestion,
    explanation: undefined,
    // Identifier of the ground truth once successfully created
    createdRephraseGroundTruthIdentifier: undefined
  });

  const [disabled, setDisabled] = useState(false);

  const saveDefinition = useCallback(async () => {
    if (!fields.originalQuestion || !fields.rephrasedQuestion) {
      return;
    }
    setDisabled(true);

    await services
      .saveRephraseGroundTruth(fields.originalQuestion, fields.rephrasedQuestion, fields.explanation)
      .then(response => {
        setFields(prev => ({ ...prev, createdRephraseGroundTruthIdentifier: response.data }));
        enqueueSnackbar(t('save-new-rephrase-ground-truth.saved-new-rephrase', { question: fields.originalQuestion }), {
          variant: 'info',
          autoHideDuration: 3000
        });
        trackEvent('New Rephrase Ground Truth Created', {
          originalQuestion: fields.originalQuestion,
          rephrasedQuestion: fields.rephrasedQuestion,
          explanation: fields.rephrasedQuestion
        });
      })
      .then(_ => onClose());
    setDisabled(false);
  }, [fields]);

  const handleInputOriginalQuestionChange = event => {
    const value = event.target.value;
    setFields(prev => ({ ...prev, originalQuestion: value }));
  };
  const handleInputRephrasedQuestionChange = event => {
    const value = event.target.value;
    setFields(prev => ({ ...prev, rephrasedQuestion: value }));
  };
  const handleInputExplanationChange = event => {
    const value = event.target.value;
    setFields(prev => ({ ...prev, explanation: value }));
  };

  const onKeyDown = useCallback(
    event => {
      if (event.key === 'Enter') {
        // preventDefault necessary, otherwise the <button /> "+" icon that opens the modal would
        // re-trigger by default, causing the modal to re-open endlessly.
        event.preventDefault();
        saveDefinition();
      }
    },
    [saveDefinition]
  );

  return (
    <div className={styles.wideForm}>
      <div>
        <Title size="small" className={styles.title}>
          {t('save-new-rephrase-ground-truth.when-i-ask')}
        </Title>
        <TextField
          layout={layouts.veezoo}
          multiline
          customClasses={{
            'MuiInputBase-multiline': {
              padding: 0
            }
          }}
          value={fields.originalQuestion}
          ref={originalQuestionInputRef}
          disabled={disabled}
          onChange={handleInputOriginalQuestionChange}
          onKeyDown={onKeyDown}
          error={!fields.originalQuestion}
          InputProps={{ 'data-test': 'saveRephraseGroundTruthFormOriginalQuestion' }}
        />
      </div>
      <div>
        <Title size="small" className={styles.title}>
          {t('save-new-rephrase-ground-truth.i-mean')}
        </Title>
        <TextField
          layout={layouts.veezoo}
          multiline
          customClasses={{
            'MuiInputBase-multiline': {
              padding: 0
            }
          }}
          value={fields.rephrasedQuestion}
          ref={rephrasedInputRef}
          disabled={disabled}
          onChange={handleInputRephrasedQuestionChange}
          onKeyDown={onKeyDown}
          error={!fields.rephrasedQuestion}
          InputProps={{ 'data-test': 'saveRephraseGtFormRephraseQuestion' }}
        />
      </div>
      <div>
        <Title size="small" className={styles.title}>
          {t('save-new-rephrase-ground-truth.explanation')}
        </Title>

        <TextArea
          layout={layouts.veezoo}
          value={fields.explanation}
          ref={explanationInputRef}
          disabled={disabled}
          onChange={handleInputExplanationChange}
          onKeyDown={onKeyDown}
          placeholder={t('save-new-rephrase-ground-truth.explanation-placeholder')}
          InputProps={{ 'data-test': 'saveRephraseGtFormExplanation' }}
        />
      </div>

      <div className={styles.buttonContainer}>
        <Button
          layout={layouts.veezoo}
          disabled={disabled}
          mode="dark"
          fullWidth
          onClick={saveDefinition}
          data-test="saveRephraseGtConfirm"
        >
          {t('teach')}
        </Button>
      </div>
    </div>
  );
};

export default withSnackbar(memo(SaveRephraseGroundTruthForm));
