import { useState, useMemo, useRef, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, CustomModal, TextField } from 'components/v3';

import { layouts } from 'config/constants';

import styles from './InputModal.scss';

const InputModalContent = ({ onClose, confirmButtonText, cancelButtonText, onConfirm, defaultText, t }) => {
  const inputRef = useRef(null);

  const initialState = {
    name: defaultText,
    dirty: false
  };

  const [state, setState] = useState(initialState);

  const handleInputChange = event => {
    const value = event.target.value;
    setState({ dirty: true, name: value });
  };

  const handleConfirm = () => {
    if (invalidName) return;
    onConfirm(state.name);
    onClose();
  };

  const invalidName = useMemo(() => state.dirty && !state.name, [state.dirty, state.name]);

  const onKeyDown = event => {
    if (event.key === 'Enter') {
      // preventDefault necessary, otherwise the <button /> "+" icon that opens the modal would
      // re-trigger by default, causing the modal to re-open endlessly.
      event.preventDefault();
      handleConfirm();
    }
  };

  const handleFocus = event => event.target.select();

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <>
      <div className={styles.inputContainer}>
        <TextField
          layout={layouts.veezoo}
          value={state.name}
          ref={inputRef}
          onFocus={handleFocus}
          onChange={handleInputChange}
          onKeyDown={onKeyDown}
          error={invalidName}
          data-test="modalInputText"
          title="Input modal"
        />
      </div>
      <div className={styles.buttonsContainer}>
        <Button layout={layouts.veezoo} classes={{ root: { width: 'calc(50% - 10px)' } }} onClick={onClose}>
          {cancelButtonText ? cancelButtonText : t('cancel')}
        </Button>
        <Button
          layout={layouts.veezoo}
          mode="dark"
          classes={{ root: { width: 'calc(50% - 10px)' } }}
          disabled={invalidName}
          onClick={handleConfirm}
          data-test="modalCreateButton"
        >
          {confirmButtonText ? confirmButtonText : 'OK'}
        </Button>
      </div>
    </>
  );
};

const InputModal = ({ title, defaultText, open, confirmButtonText, cancelButtonText, onConfirm, onClose, t }) => (
  <CustomModal
    layout={layouts.veezoo}
    title={title}
    open={open}
    content={
      <InputModalContent
        defaultText={defaultText}
        onClose={onClose}
        confirmButtonText={confirmButtonText}
        cancelButtonText={cancelButtonText}
        onConfirm={onConfirm}
        t={t}
      />
    }
    onClose={onClose}
    hideButtons
  />
);

export default withTranslation('veezoo')(InputModal);

InputModal.propTypes = {
  title: PropTypes.string,
  defaultText: PropTypes.string,
  open: PropTypes.bool,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  t: PropTypes.func
};
