import { tss } from 'tss-react';

import { veezooStyles } from '../defaultStyles';

const lightBlue = 'var(--light-blue)';
const lightGray = 'var(--light-gray)';
const lightestGray = 'var(--lightest-gray)';
const primaryColor = 'var(--primary-color)';
const secondaryColor = 'var(--secondary-color)';
const disabledVeezooBlue = 'var(--disabled-veezoo-blue)';

const signupRoot = {
  size: {
    mobile: {
      fontSize: '14px',
      padding: '0 9px',
      minWidth: '50px',
      fontWeight: '300',
      borderRadius: '4px'
    },
    small: {
      fontSize: '14px',
      padding: '0 9px',
      minWidth: '50px'
    },
    large: {
      fontSize: '16px',
      padding: '6 10px',
      minHeight: '50px'
    },
    default: {
      fontSize: '17px',
      fontWeight: '300',
      padding: '2px 20px',
      minWidth: '120px'
    },
    onlyFontLarge: {
      fontSize: '20px'
    }
  },
  color: {
    dark: {
      backgroundColor: lightBlue,
      color: 'white',
      border: `solid thin ${lightBlue}`,
      '&&:hover': {
        border: 'solid thin transparent',
        backgroundColor: primaryColor
      }
    },
    light: {
      backgroundColor: 'white',
      color: lightBlue,
      border: `solid thin ${lightBlue}`,
      '&&:hover': {
        backgroundColor: lightGray
      }
    },
    default: {
      backgroundColor: 'white',
      color: 'black',
      border: 'solid thin black',
      '&&:hover': {
        backgroundColor: lightGray
      }
    },
    simple: {
      color: 'black',
      '&&:hover': {
        backgroundColor: lightGray
      }
    }
  }
};

const veezooRoot = {
  size: {
    inputInline: {
      height: '32px',
      maxHeight: '32px'
    },
    default: {}
  }
};

export const useTssStyles = tss.create(
  ({ iconSize, mode, secondaryColor: hasSecondaryColor, outerClasses, layout }) => {
    const buttonColor = hasSecondaryColor ? secondaryColor : primaryColor;

    const layoutSpecificStyles = {
      signup: {
        labelSpan: {
          marginLeft: '7px'
        }
      },
      veezoo: {
        labelSpan: {
          justifyContent: 'space-evenly',
          marginLeft: '7px',
          marginRight: '7px'
        },
        icon: {
          fill: mode === 'dark' ? 'white' : buttonColor
        },
        arrowIcon: {
          width: '15px',
          height: '15px',
          marginLeft: '8px',
          '& > path': { fill: 'var(--light-medium-gray)' }
        }
      },
      default: {}
    };

    return {
      icon: {
        width: `${iconSize || '15px'} !important`,
        height: `${iconSize || '15px'} !important`,
        ...layoutSpecificStyles[layout]?.icon,
        ...(outerClasses?.icon || {})
      },
      labelSpan: {
        ...layoutSpecificStyles[layout]?.labelSpan,
        ...(outerClasses?.labelSpan || {})
      },
      arrowIcon: {
        ...layoutSpecificStyles[layout]?.arrowIcon,
        ...(outerClasses?.arrowIcon || {})
      }
    };
  }
);

export const useStyles = ({
  size,
  mode,
  secondaryColor: hasSecondaryColor,
  noBorders,
  fullWidth,
  iconOnly,
  reducedWidth,
  outerClasses
}) => {
  const root = outerClasses?.root || {};
  const rootHover = outerClasses?.root?.['&:hover'] || {};
  const rootDisabled = outerClasses?.root?.['&&:disabled'] || {};

  const buttonColor = hasSecondaryColor ? secondaryColor : primaryColor;

  return {
    signup: {
      '&.MuiButtonBase-root': {
        borderRadius: '5px',
        textTransform: 'initial',
        ...(signupRoot.size?.[size] || signupRoot.size.default),
        ...(signupRoot.color?.[mode] || signupRoot.mode.default),
        ...root,
        '&&:disabled': {
          backgroundColor: disabledVeezooBlue,
          borderColor: disabledVeezooBlue,
          color: 'white',
          ...rootDisabled
        },
        '&:hover': {
          ...rootHover
        }
      }
    },
    veezoo: {
      '&.MuiButtonBase-root': {
        borderRadius: '4px',
        fontSize: '14px',
        fontFamily: veezooStyles.fontFamily,
        fontWeight: 400,
        textTransform: 'initial',
        minWidth: iconOnly || reducedWidth ? 32 : 110,
        width: fullWidth ? '100%' : 'initial',
        padding: noBorders ? '0 5px' : '10px 16px',
        height: 36,
        backgroundColor: noBorders ? 'transparent' : mode === 'dark' ? buttonColor : 'white',
        border: noBorders ? 'none' : `solid thin ${mode === 'dark' ? buttonColor : lightGray}`,
        color: noBorders ? buttonColor : mode === 'dark' ? 'white !important' : buttonColor,
        ...(veezooRoot.size?.[size] || veezooRoot.size.default),
        ...root,
        '&:hover': {
          opacity: 0.7,
          backgroundColor: noBorders ? 'transparent' : mode === 'dark' ? buttonColor : lightestGray,
          color: noBorders ? buttonColor : mode === 'dark' ? 'white' : 'inherit',
          ...rootHover
        },
        '&&:disabled': {
          backgroundColor: disabledVeezooBlue,
          border: 'none',
          color: 'white',
          '& path': {
            fill: 'white'
          },
          ...rootDisabled
        }
      }
    },
    default: {
      '&.MuiButtonBase-root': {
        ...root,
        '&&:disabled': {
          ...rootDisabled
        },
        '&:hover': {
          ...rootHover
        }
      }
    }
  };
};
