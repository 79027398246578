import { Redirect, Route, withRouter } from 'react-router-dom';
import NoMobileSupport from 'components/NoMobileSupport/NoMobileSupport';
import { contextPath } from 'config/baseURL';
import { veezooRoutes } from './routes';
import useMediaQuery from 'utils/mediaQueries';

export const RedirectToLogin = withRouter(({ location }) => {
  if (location.pathname === veezooRoutes.settings) return <Redirect to={veezooRoutes.login} />;

  const params = new URLSearchParams();
  params.set('redirectTo', location.pathname + location.search);

  return <Redirect to={`${veezooRoutes.login}?${params.toString()}`} />;
});

export const RedirectToVeezoo = withRouter(({ location }) => {
  const params = new URLSearchParams(location.search);
  const redirectTo = params.get('redirectTo');
  const prefixes = ['admin', 'studio'];
  if (prefixes.find(prefix => redirectTo?.startsWith(prefix) || redirectTo?.startsWith(contextPath + '/' + prefix))) {
    // for Admin and Studio we need to reload the page
    return window.open(redirectTo, '_self');
  }

  return <Redirect to={redirectTo || veezooRoutes.chat} />;
});

export const RedirectWithParams = withRouter(({ location, to }) => <Redirect to={to + location.search} />);

export const CustomRoute = ({ requiresAuthorization, isAuthorized, isLoginScreen, allowsMobileFormat, ...rest }) => {
  const isMobile = useMediaQuery();

  // specifies whether Veezoo is currently being access via SAML authentication
  const isSaml = window.veezoo.authenticationType === 'saml';

  // when using SAML, we are already authorized when the frontend has been served, i.e. we don't initially need a
  // redirect to the login page if not authenticated (and when the authentication expires, 'UNSAFE_componentWillUpdate'
  // in 'App.js' will reload the page)
  if (!isSaml && requiresAuthorization && !isAuthorized) return <Route {...rest} render={() => <RedirectToLogin />} />;
  if (isLoginScreen && isAuthorized) return <Route {...rest} render={() => <RedirectToVeezoo />} />;
  if (!allowsMobileFormat && isMobile) return <Route {...rest} render={() => <NoMobileSupport />} />;
  return <Route {...rest} />;
};
