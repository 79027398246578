import { tss } from 'tss-react';

export const useIconStyles = tss.create(({ isMobile }) => ({
  root: {
    color: 'var(--light-gray)',
    display: 'flex',
    height: 22,
    alignItems: 'center'
  },
  base: {
    width: isMobile ? 18 : 30,
    height: isMobile ? 18 : 30,
    borderRadius: '50%',
    color: 'white',
    textAlign: 'center',
    fontSize: isMobile ? '9px' : '16px',
    paddingTop: '2px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  circle: {
    backgroundColor: 'var(--light-gray)'
  },
  active: {
    backgroundColor: 'var(--light-blue)'
  },
  completed: {
    backgroundColor: 'var(--light-blue)'
  }
}));

export const useConnectorStyles = ({ isMobile }) => ({
  '&.MuiStepConnector-alternativeLabel': {
    top: 10,
    ...(isMobile
      ? { left: 'calc(-58% + 12.5px)', right: 'calc(41% + 11px)' }
      : { left: 'calc(-50% + 15px)', right: 'calc(50% + 15px)' }),
    '&.Mui-active .MuiStepConnector-line': {
      borderColor: 'var(--light-blue)'
    },
    '&.Mui-completed .MuiStepConnector-line': {
      borderColor: 'var(--light-blue)'
    }
  },
  '& .MuiStepConnector-line': {
    borderColor: 'var(--light-gray)',
    borderTopWidth: 3,
    borderRadius: 1
  }
});

export const useStepperStyles = () => ({
  '&.MuiStepper-root': {
    backgroundColor: 'transparent',
    margin: '0 -21px',
    padding: 0
  }
});
