import { tss } from 'tss-react';

export const useStyles = tss.create({
  root: {
    '&': {
      lineHeight: '23px'
    },
    '& h1': {
      fontSize: '1.7em',
      marginTop: 0
    },
    '& h2': {
      fontSize: '1.4em'
    },
    '& h3': {
      fontSize: '1.1em'
    },
    '& ul': {
      paddingLeft: '2em'
    },
    '& ol': {
      paddingLeft: '2em'
    }
  },
  dataReference: {
    cursor: 'pointer',
    border: 'solid 1px var(--border-light-color)',
    borderRadius: '3px',
    paddingLeft: '2px',
    paddingRight: '2px'
  }
});
