import { useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { useStyles } from './ChatMessageMarkdownHtml.styles';
import { useSpecialLinkHandler } from 'components/message/commonFunctions';
import { InfoTooltip } from 'components/v3';

import parse from 'html-react-parser';

// a special markdown HTML container for chat messages which supports running chat-specific actions
// when clicking special hyperlinks (e.g., asking a suggested question)
const ChatMessageMarkdownHtml = ({ username, html, scrollTo, chatMessages, dispatch, t }) => {
  const containerRef = useRef(null);
  const { classes } = useStyles();
  useSpecialLinkHandler(containerRef, username, dispatch, html, t);

  // Cache the mapping of partial answer IDs to chat UI message IDs
  const partialAnswerIdToMessageId = useMemo(() => {
    const map = new Map();

    chatMessages.forEach(msg => {
      if (msg.answer?.partialAnswerId) {
        // single answer
        map.set(msg.answer.partialAnswerId, msg.id);
      } else if (msg.widgetsToPartialAnswersIdMap) {
        // board answer: remember the reference for the partial answers in all widgets
        msg.widgetsToPartialAnswersIdMap.forEach(widget => {
          map.set(widget.partialAnswerId, msg.id);
        });
      }
    });

    return map;
  }, [chatMessages]);

  const parseAndReplaceSpecialTags = html => {
    return parse(html, {
      replace: domNode => {
        if (domNode.name === 'xveezoodata') {
          // NOTE: Markdown conversion has converted all attributes to lowercase
          const partialAnswerId = domNode.attribs?.partialanswerid;
          const referencedMessageId = partialAnswerIdToMessageId.get(partialAnswerId);
          return (
            <InfoTooltip text={t('text-answer-message.datapoint-tooltip')} placement="top">
              <span className={classes.dataReference} onClick={() => scrollTo(referencedMessageId)}>
                {domNode.children[0].data}
              </span>
            </InfoTooltip>
          );
        }
      }
    });
  };

  const renderedContent = parseAndReplaceSpecialTags(html);

  return (
    <div ref={containerRef} className={classes.root}>
      {renderedContent}
    </div>
  );
};

const mapStateToProps = state => ({
  username: state.user.username,
  chatMessages: state.chatMessages
});

export default withTranslation('veezoo')(connect(mapStateToProps)(ChatMessageMarkdownHtml));
