import { useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, CustomModal } from 'components/v3';

import { layouts } from 'config/constants';

import styles from './ConfirmationModal.scss';

const Content = ({ defaultText, children, confirmButtonText, cancelButtonText, onConfirm, onClose, t, ...props }) => {
  const confirmButtonRef = useRef(null);

  useEffect(() => {
    if (open && confirmButtonRef.current) {
      confirmButtonRef.current.focus();
    }
  }, [open, confirmButtonRef.current]);

  // if no children are provided, show a basic paragraph with the given defaultText
  const content = children || <p>{defaultText}</p>;

  return (
    <div className={styles.confirmationModal}>
      {content}
      <div className={styles.buttonsContainer}>
        <Button layout={layouts.veezoo} classes={{ root: { width: 'calc(50% - 10px)' } }} onClick={onClose}>
          {cancelButtonText ? cancelButtonText : t('cancel')}
        </Button>
        <Button
          ref={confirmButtonRef}
          layout={layouts.veezoo}
          mode="dark"
          classes={{ root: { width: 'calc(50% - 10px)' } }}
          onClick={onConfirm}
          data-test="confirmationTooltipConfirm"
        >
          {confirmButtonText ? confirmButtonText : 'OK'}
        </Button>
      </div>
    </div>
  );
};

const ConfirmationModal = ({ title, open, onClose, ...props }) => (
  <CustomModal
    layout={layouts.veezoo}
    title={title}
    content={<Content onClose={onClose} {...props} />}
    open={open}
    onClose={onClose}
    hideButtons
    hideCloseIcon
    {...props}
  />
);

export default withTranslation('veezoo')(ConfirmationModal);

Content.propTypes = {
  defaultText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  t: PropTypes.func
};

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  defaultText: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

ConfirmationModal.defaultProps = {
  open: false
};
