import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Collapse from 'react-bootstrap/Collapse';
import classnames from 'classnames';
import { stopEditBoard } from 'store/modules/board';
import {
  fetchCustomersOfTheDay,
  fetchCustomerSelectionAnswer,
  getCustomerSelections
} from 'store/modules/customers-of-the-day';

import { openCustomerSelectionView, stopCustomerSelectionView } from 'store/modules/customer-selections';
import { fetchBoardMessage } from 'store/modules/chat-messages';

import { REJECTED_STATUS, SIDEBAR_EXPANDED_SIZE, SIDEBAR_MIN_SIZE } from 'config/constants';
import DiscoveryTab from 'components/sidebar/tab/DiscoveryTab';
import ChatTab from 'components/sidebar/tab/ChatTab';
import KnowledgeGraphTab from 'components/sidebar/tab/KnowledgeGraphTab';
import StudioTab from 'components/sidebar/tab/StudioTab';

import { setEntitiesSidebar } from 'store/modules/graph/graph';

import SidebarFooter from 'components/sidebar/SidebarFooter';
import SidebarHeader from 'components/sidebar/SidebarHeader';
import BoardSidebarList from 'components/sidebar/board/BoardSidebarList';
import CustomerSelectionSidebarList from 'components/sidebar/selection/CustomerSelectionSidebarList';

import styles from './sidebar.scss';

import useMediaQuery from 'utils/mediaQueries';
import { embeddedMessageTypes, postMessageToParentWindow } from 'root/hocs/withEmbedded';
import useLocalStorageState from 'use-local-storage-state';
import { initialSidebarSizeState, LocalStorageSidebarSizeKey } from 'components/split/SplitSidebarView';

const buttonClass = 'button-secondary';

// support IE11
const style = {
  marginTop: 0
};

const Sidebar = ({
  boardsList,
  chatMessages,
  customerSelections,
  isBoardEditing,
  isOpenCustomerSelectionRoute,
  isAlreadyFetchingCustomersOfTheDay,
  sidebarBoardFetchingInfo,
  showDiscovery,
  showChat,
  showSettings,
  showGraph,
  themeName,
  username,
  isEnabledCustomersOfTheDay,
  shouldHighlightCoD,
  meta,
  isSuperUserOrHigher,
  shouldShowDiscoveryButton,
  dispatch,
  t
}) => {
  const isMobile = useMediaQuery();

  const [sidebarSize, setSidebarSize] = useLocalStorageState(LocalStorageSidebarSizeKey, {
    defaultValue: initialSidebarSizeState(),
    storageSync: false
  });

  useEffect(() => {
    dispatch(getCustomerSelections()); // display customer selections before boards
  }, []);

  const stopBoardEditingMode = () => {
    if (isBoardEditing) dispatch(stopEditBoard());
  };

  const closeCustomerSelectionView = () => {
    if (isOpenCustomerSelectionRoute) {
      dispatch(stopCustomerSelectionView());
    }
  };

  const handleCustomerSelectionRoute = () => {
    stopBoardEditingMode(); // switch from BoardEditingMode
    dispatch(openCustomerSelectionView());
  };

  const handleCustomersOfTheDay = () => {
    // Prevent running multiple requests for customers of the day at the same time
    if (!isAlreadyFetchingCustomersOfTheDay) {
      dispatch(fetchCustomersOfTheDay());
    }

    stopBoardEditingMode();
    closeCustomerSelectionView();
  };

  const handleCustomerSelections = customerSelectionId => {
    let isFetchingCustomerSelection;
    if (customerSelections.length > 0) {
      isFetchingCustomerSelection = customerSelections.find(selection => selection.id === customerSelectionId);
    }
    // Prevent running multiple customer selection requests at the same time
    if (!isFetchingCustomerSelection || !isFetchingCustomerSelection.isAlreadyFetchingCustomerSelection) {
      dispatch(fetchCustomerSelectionAnswer(customerSelectionId));
    }

    stopBoardEditingMode(); // switch from full edit mode otherwise board
    closeCustomerSelectionView(); // switch from Customer Selection View
  };

  const handleSwitchModes = () => {
    stopBoardEditingMode();
    closeCustomerSelectionView();
    dispatch(setEntitiesSidebar({ isOpen: false }));
  };

  const handleFetchBoardMessage = boardId => {
    let fetchingMessageBoard;
    if (chatMessages.length > 0) {
      // if there are messages in Chat
      fetchingMessageBoard = sidebarBoardFetchingInfo.find(message => message.boardId === boardId);
    }

    // Prevent running multiple customer selection requests at the same time while clicking at the same Board
    if (fetchingMessageBoard !== undefined) {
      // if board was already fetched
      const { isAlreadyFetchingMessageBoard } = fetchingMessageBoard;
      if (!isAlreadyFetchingMessageBoard) {
        dispatch(fetchBoardMessage(boardId, t));
      }
    } else {
      // initial load for fetching Board Message
      dispatch(fetchBoardMessage(boardId, t));
    }
  };

  const ownBoards = useMemo(() => boardsList.filter(board => board.isOwned === true), [boardsList]);

  const sharedBoards = useMemo(() => boardsList.filter(board => board.isOwned === false), [boardsList]);

  const sidebarCustomerSelections =
    customerSelections.length > 0 &&
    customerSelections.filter(
      selection =>
        selection.isInSidebar && (!selection.userSharingStatus || selection.userSharingStatus !== REJECTED_STATUS)
    );

  const selectedChatClass = classnames(buttonClass, {
    [styles.selected]: showChat,
    [styles.selectedChat]: showChat
  });

  // sends a message containing all boards to the parent window embedding Veezoo
  useEffect(() => {
    // only send once the boards are available
    if (boardsList.length) {
      const boards = [...sharedBoards, ...ownBoards].map(board => ({
        id: board.id,
        name: board.name,
        isOwned: board.isOwned
      }));
      const data = {
        boards: boards
      };
      postMessageToParentWindow(embeddedMessageTypes.boards, data);
    }
  }, [boardsList, ownBoards, sharedBoards]);

  return (
    <aside className={styles.sidebarWrapper} data-knowledgegraph-tutorial="sidebar">
      <SidebarHeader
        themeName={themeName}
        handleSwitchModes={handleSwitchModes}
        showGraph={showGraph}
        setIsMinimized={() =>
          setSidebarSize({
            isMinimized: !sidebarSize.isMinimized,
            width: sidebarSize.isMinimized ? SIDEBAR_EXPANDED_SIZE : SIDEBAR_MIN_SIZE
          })
        }
        isMinimized={sidebarSize.isMinimized}
      />

      <div className={styles.mainContentSidebar}>
        <ChatTab selectedChatClass={selectedChatClass} handleSwitchModes={handleSwitchModes} t={t} />

        <Collapse style={style} in={showChat}>
          <div className={styles.container}>
            <div className={styles.wrapper}>
              {(isEnabledCustomersOfTheDay || customerSelections.length > 0) && (
                <CustomerSelectionSidebarList
                  sidebarCustomerSelections={sidebarCustomerSelections}
                  isEnabledCustomersOfTheDay={isEnabledCustomersOfTheDay}
                  shouldHighlightCoD={shouldHighlightCoD}
                  defaultEntryPoint={meta.defaultEntryPoint}
                  handleCustomersOfTheDay={handleCustomersOfTheDay}
                  handleCustomerSelections={handleCustomerSelections}
                  handleCustomerSelectionRoute={handleCustomerSelectionRoute}
                  t={t}
                  dispatch={dispatch}
                />
              )}
              <BoardSidebarList
                sharedBoards={sharedBoards}
                ownBoards={ownBoards}
                defaultEntryPoint={meta.defaultEntryPoint}
                t={t}
                dispatch={dispatch}
                handleFetchBoardMessage={handleFetchBoardMessage}
              />
            </div>
          </div>
        </Collapse>

        {shouldShowDiscoveryButton && isMobile && (
          <DiscoveryTab sidebarIsMinimized={sidebarSize.isMinimized} showDiscovery={showDiscovery} t={t} />
        )}
        {meta.hasKnowledgeGraphSupport && (
          <KnowledgeGraphTab sidebarIsMinimized={sidebarSize.isMinimized} disabled={isMobile} t={t} />
        )}
        {isSuperUserOrHigher && <StudioTab sidebarIsMinimized={sidebarSize.isMinimized} disabled={isMobile} t={t} />}
      </div>

      <SidebarFooter showSettings={showSettings} username={username} meta={meta} t={t} />
    </aside>
  );
};

export const mapStateToProps = state => ({
  username: state.user.username,
  themeName: state.theme.themeDetails.name,
  boardsList: state.board.boardsList,
  isEnabledCustomersOfTheDay: state.customersOfTheDay.isEnabled,
  shouldHighlightCoD: state.customersOfTheDay.shouldHighlightInSidebar,
  customerSelections: state.customersOfTheDay.selections, // todo: move to customer selection reducer
  meta: state.knowledgeGraphMeta.meta,
  isOpenCustomerSelectionRoute: state.customerSelections.isOpenCustomerSelectionRoute,
  isAlreadyFetchingCustomersOfTheDay: state.customersOfTheDay.isAlreadyFetchingCustomersOfTheDay,
  isBoardEditing: state.board.editingBoardId !== undefined,
  chatMessages: state.chatMessages,
  sidebarBoardFetchingInfo: state.network.sidebarBoardFetchingInfo,
  isSuperUserOrHigher: state.user.isSuperUser || state.user.isAdmin,
  shouldShowDiscoveryButton:
    (state.discovery.topics && state.discovery.topics?.length > 0) ||
    (state.knowledgeGraphMeta.meta.hasWritePermission && state.user.isLlmParserEnabled)
});

export default withTranslation('veezoo')(withRouter(connect(mapStateToProps)(Sidebar)));

Sidebar.propTypes = {
  showChat: PropTypes.bool,
  showDiscovery: PropTypes.bool,
  showGraph: PropTypes.bool,
  showSettings: PropTypes.bool,
  username: PropTypes.string,
  themeName: PropTypes.string,
  boardsList: PropTypes.array,
  isEnabledCustomersOfTheDay: PropTypes.bool,
  shouldHighlightCoD: PropTypes.bool,
  customerSelections: PropTypes.array,
  isOpenCustomerSelectionRoute: PropTypes.bool,
  isAlreadyFetchingCustomersOfTheDay: PropTypes.bool,
  chatMessages: PropTypes.array,
  sidebarBoardFetchingInfo: PropTypes.array,
  isSuperUserOrHigher: PropTypes.bool,
  isBoardEditing: PropTypes.bool,
  shouldShowDiscoveryButton: PropTypes.bool
};

Sidebar.defaultProps = {
  username: ''
};
