import styles from './answer-explanation.scss';
import FilterPillContainer from 'components/shared/FilterPillContainer';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import { InfoTooltip } from 'components/v3';
import EditIcon from '@mui/icons-material/Edit';
import Q2QEditableEntityPassage from './Q2QEditableEntityPassage';
import React from 'react';
import { Q2QStateContextProvider } from './Q2QStateContext';
import useMediaQuery from 'utils/mediaQueries';

const AnswerExplanation = ({
  filterObjects,
  answerData,
  answerId,
  messageId,
  t,
  titleObject,
  followUpTag,
  shouldAlertUser,
  dispatch,
  hasAlternatives,
  isShowingAlternatives,
  alternativesButtonCallback,
  possibleToAddNewFilter,
  titleClickCallback
}) => {
  const classes = classnames({
    [styles.greyedOutTitle]: titleObject.isGreyedOut,
    [styles.highlightedTitle]: titleObject.isHighlighted,
    [styles.answerTitle]: !titleObject.isHighlighted && !titleObject.isGreyedOut
  });
  const isMobile = useMediaQuery();

  const warningIconClasses = classnames(styles.pulse, {
    [styles.disablePulse]: isShowingAlternatives || !shouldAlertUser
  });

  const tooltipText = shouldAlertUser ? t('did-you-mean.tooltip-not-sure') : t('did-you-mean.tooltip');

  const alternativesButton = hasAlternatives && (
    <span className={styles.alternativesToggleButton} onClick={alternativesButtonCallback}>
      <InfoTooltip text={tooltipText}>
        <span className={warningIconClasses}>
          <EditIcon variant="outlined" fontSize="large" style={{ color: 'darkgrey' }} />
        </span>
      </InfoTooltip>
    </span>
  );

  const title = Array.isArray(titleObject.title) ? (
    <Q2QEditableEntityPassage passages={titleObject.title} disableEditing />
  ) : (
    titleObject.title
  );

  return (
    <Q2QStateContextProvider answerData={answerData}>
      <div className={styles.answerExplanation}>
        {isMobile && followUpTag}
        <h3 className={classes}>
          <span data-testclass="answerTitle" onClick={titleClickCallback}>
            {title}
          </span>
          {alternativesButton}
          {!isMobile && followUpTag}
        </h3>
        <FilterPillContainer
          title={t('filters-title')}
          list={filterObjects}
          noDataMessage=" " // display an empty string for this case
          dispatch={dispatch}
          answerData={answerData}
          answerId={answerId}
          messageId={messageId}
          possibleToAddNewFilter={possibleToAddNewFilter}
        />
      </div>
    </Q2QStateContextProvider>
  );
};

export default withTranslation('veezoo')(AnswerExplanation);
