import { memo } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import FollowUpButton from 'components/buttons/FollowUpButton';
import ShareButton from 'components/buttons/ShareButton';
import StopSharingButton from 'components/buttons/StopSharingButton';
import PrintButton from 'components/buttons/PrintButton';

import styles from './answer-message-footer.scss';
import SaveBoardButton from 'components/buttons/SaveBoardButton';
import withEmbedded from 'root/hocs/withEmbedded';
import useMediaQuery from 'utils/mediaQueries';

import { showSharedBoardModal } from 'store/modules/sharedModal';
import { unshareBoard } from 'store/modules/board';
import { trackEvent } from 'utils/eventTracking';
import DownloadButton from 'components/buttons/DownloadButton';

const BoardAnswerMessageFooter = ({
  answerId,
  messageId,
  followUpState,
  dispatch,
  board,
  metaPartialAnswers,
  t,
  targetRef,
  isDemoKg,
  isEmbedded
}) => {
  const isMobile = useMediaQuery();
  const isSharedWithOthers = board && board.isSharedWithOthers;

  const stopSharing = boardId => {
    trackEvent('Board Sharing Stopped via Footer', { boardId });
    dispatch(unshareBoard(boardId));
  };

  const openSharedBoardModal = boardId => {
    trackEvent('Board Sharing Modal Opened via Footer', { boardId });
    dispatch(showSharedBoardModal(boardId));
  };

  return (
    <div className={styles.buttonsContainer}>
      <ul className={styles.list}>
        <li className={styles.item}>
          {' '}
          <FollowUpButton answerId={answerId} messageId={messageId} followUpState={followUpState} dispatch={dispatch} />
        </li>
        {!isEmbedded && board.isOwned && !isDemoKg && (
          <div>
            <li className={styles.item}>
              <ShareButton
                id={board.id}
                dispatch={dispatch}
                showModal={openSharedBoardModal}
                isSharedWithOthers={isSharedWithOthers}
                tooltipText={t('tooltip.share-board')}
              />
            </li>
            {isSharedWithOthers && (
              <li className={styles.item}>
                <StopSharingButton
                  id={board.id}
                  stopSharingAction={stopSharing}
                  tooltipText={t('tooltip.stop-sharing-board')}
                  dispatch={dispatch}
                />
              </li>
            )}
          </div>
        )}
        {/* leave answerId as undefined so that the copied board is not linked to the original, but remains independent */}
        <li className={styles.item}>
          {' '}
          {/* NOTE: We don't re-use the copyBoard endpoint here for copying, as the endpoint always copies the active
                    version of the baord while here we want to copy the widgets of the effectively shown board. This
                    can make a difference if for example a shared board is updated by its owner in the meantime. */}
          <SaveBoardButton
            answerId={undefined}
            originalBoardName={board.name}
            metaPartialAnswers={metaPartialAnswers}
            dispatch={dispatch}
            isCopyButton
          />
        </li>
        <li className={styles.item}>
          <DownloadButton
            partialAnswerOptions={metaPartialAnswers.map(p => ({
              partialAnswerId: p.fromPartialAnswer,
              visualizationType: p.type,
              visualizationOptions: p.visualizationOptions
            }))}
          />
        </li>
        {!isMobile && (
          <li className={styles.item}>
            <PrintButton targetRef={targetRef} />
          </li>
        )}
      </ul>
    </div>
  );
};

const mapStateToProps = state => ({
  followUpState: state.followUpState,
  isDemoKg: state.knowledgeGraphMeta.meta.isDemo
});

export default withTranslation('veezoo')(connect(mapStateToProps)(withEmbedded(memo(BoardAnswerMessageFooter))));
