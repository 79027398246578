import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form/dist/index.ie11';
import { yupResolver } from '@hookform/resolvers/dist/ie11/yup';
import * as yup from 'yup';
import clsx from 'clsx';

import Title from 'components/Signup/Title';
import PageLoader from 'components/Signup/PageLoader';
import MessageModal from 'components/Signup/MessageModal';

import { Button, PasswordField, Stepper, TextField } from 'components/v3';

import { signup } from 'store/modules/network';
import services from 'services';

import { trackEvent } from 'utils/eventTracking';
import { handleError } from 'services/http';

import styles from '../index.module.scss';
import signupStyles from 'pages/Signup/signup.styles.scss';

import StepperContainer from 'components/Signup/StepperContainer/StepperContainer';

import { layouts } from 'config/constants';

const customDisabledColor = { disabled: { color: 'var(--light-blue) !important' } };

const validationRules = yup.object().shape({
  firstName: yup
    .string()
    .test('len', 'Must be lower than 50 characters', val => val.length < 50)
    .required('First Name is required'),
  lastName: yup
    .string()
    .test('len', 'Must be lower than 50 characters', val => val.length < 50)
    .required('Last Name is required'),
  email: yup
    .string()
    .required('E-mail is required')
    .email('Format should be like: email@domain.com'),
  password: yup.string().required('Password is required')
});

const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: ''
};

const getErrorMessage = error => {
  return (error && error.message) || '';
};

const getInvitationErrorMessage = errorCode => {
  switch (errorCode) {
    case 1:
      return "The invitation wasn't found";
    case 2:
      return 'The invitation has expired and can no longer be used';
    case 3:
      return 'The invitation has already been accepted and can no longer be used';
    default:
      return 'There was an unknown error getting the invitation';
  }
};

const CreateAccount = ({
  setStep,
  invitationId,
  signupEmail,
  isSigningUp,
  signupSuccess,
  signupFailure,
  signupStatusCode,
  signupError,
  isFirstTimeLogging,
  isAuthorized,
  username,
  dispatch
}) => {
  const [savedFields, setSavedFields] = useState(null);

  // true while the request to get the invitation (if any) is pending
  const [invitationIsPending, setInvitationIsPending] = useState(true);
  // the invitation
  const [invitation, setInvitation] = useState(null);
  // an error code if getting the invitation failed
  //   1: "not found", 2: "expired", 3: "already accepted": 4: "unknown error"
  const [invitationErrorCode, setInvitationErrorCode] = useState(null);
  const [modal, setModal] = useState({
    open: false,
    message: 'Modal message',
    type: 'info'
  });

  const history = useHistory();

  const { handleSubmit, control, errors, reset, setValue } = useForm({
    defaultValues,
    resolver: yupResolver(validationRules)
  });

  const title = useMemo(() => {
    if (invitationId) {
      if (invitationIsPending) return <></>;
      if (invitation) {
        return <>Join your colleagues on Veezoo.</>;
      }
      return <>Invitation error</>;
    }
    return <>Complete your Profile.</>;
  }, [invitationIsPending, invitationId, invitation]);

  const fetchInvitation = useCallback(
    async id => {
      const response = await services.getInvitation(id);
      handleError(response, dispatch);
      if (response.success) {
        const newInvitation = response.data.data;
        if (newInvitation.isExpired) {
          // invitation expired
          setInvitationErrorCode(2);
        } else if (newInvitation.isAccepted) {
          // invitation already accepted
          setInvitationErrorCode(3);
        } else {
          setInvitation(newInvitation);
        }
      } else if (response.response.status === 404) {
        // not found
        setInvitationErrorCode(1);
      } else {
        // unknown error
        setInvitationErrorCode(4);
      }
      setInvitationIsPending(false);
    },
    [invitationId, setValue, setInvitation, setInvitationErrorCode]
  );

  const reopenForm = useCallback(() => {
    setModal(prevModal => ({ ...prevModal, open: false }));
    reset(savedFields);
  }, [setModal, savedFields, reset]);

  const onSubmit = useCallback(
    data => {
      setSavedFields(data);

      const user = {
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
        password: data.password,
        ...(invitation ? { invitationId: invitation.id } : { email: data.email.trim() }),
        locale: Intl.DateTimeFormat().resolvedOptions().locale || 'en-US'
      };

      const credentials = {
        email: data.email.trim(),
        password: data.password
      };

      dispatch(signup(user, credentials));
    },
    [dispatch, signup, setSavedFields, invitationId, invitation]
  );

  useEffect(() => {
    if (signupSuccess) {
      if (isAuthorized) {
        if (invitationId) {
          trackEvent('Invitation Accepted', {});
          history.push('/chat');
          return;
        }
        if (username) {
          setStep(step => step + 1);
        }
      }
    }
  }, [history, signupSuccess, isAuthorized, username, setStep, invitationId]);

  useEffect(() => {
    if (signupFailure) {
      let message;
      if (signupStatusCode === 409) {
        message = 'The provided email address already exists.';
      } else if (signupStatusCode === 403) {
        message =
          'Signing up is disabled since your organization is using single sign-on with SAML. Instead, you have to be given access to Veezoo at your Identity Provider. Please reach out to your IT administrator.';
      } else if (signupError.data.validationErrors?.length) {
        // use the first validation error
        const validationError = signupError.data.validationErrors[0];
        // transform camel case property name to lowercase words
        const field = validationError.path.replace(/([A-Z])/g, ' $1').toLowerCase();
        message = `Field '${field}'`;
        if (validationError.type === 'error.invalidCharacter') {
          message += ` contains invalid characters: ${validationError.values}`;
        } else {
          message += `: ${signupError.data.message}`;
        }
      }
      setModal({
        open: true,
        type: 'error',
        message: (
          <span>
            There was an error while signing up.
            <br />
            {message}
          </span>
        )
      });
    }
  }, [signupFailure, setModal]);

  useEffect(() => {
    if (invitationId) {
      fetchInvitation(invitationId);
    }
  }, [invitationId, fetchInvitation]);

  useEffect(() => {
    // Set the values in the form based on the signup variables
    Object.keys(defaultValues).forEach(field => {
      window.veezoo?.signupVariables?.signup?.[field] &&
        setValue(field, window.veezoo?.signupVariables?.signup?.[field]);
    });

    if (signupEmail) {
      setValue('email', signupEmail);
    } else if (invitation) {
      setValue('email', invitation.email);
    }
  }, [invitation, signupEmail]);

  return (
    <>
      <StepperContainer customStepper={<Stepper step={1} totalSteps={5} />}>
        <div className={signupStyles.centerBothDirectionsParent}>
          <div className={signupStyles.centerBothDirectionChild}>
            <div>
              <Title>{title}</Title>
              {(!invitationId || !!invitation) && (
                <div className={signupStyles.subTitle}>Veezoo needs your name to refer to you</div>
              )}
            </div>
            {!!invitationId && invitationIsPending && <PageLoader message="Getting the invitation..." />}
            {!!invitationId && !!invitationErrorCode && (
              <div className={signupStyles.mt_40}>
                <span className={signupStyles.invitationErrorMessage}>
                  {getInvitationErrorMessage(invitationErrorCode)}.
                  <br />
                  <br />
                  Please reach out to the person who invited you or contact us at{' '}
                  <a href="mailto:support@veezoo.com">support@veezoo.com</a>.
                </span>
              </div>
            )}
            {(!invitationId || !!invitation) && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={signupStyles.pageContent}>
                  {isFirstTimeLogging || isSigningUp ? (
                    <PageLoader message="Creating user, please wait a minute..." />
                  ) : (
                    <>
                      <div>
                        <div className={signupStyles.sidedFormFields}>
                          <div className={clsx(signupStyles.formField, styles.half, signupStyles.mr_30)}>
                            <Controller
                              as={TextField}
                              layout={layouts.signup}
                              name="firstName"
                              control={control}
                              label="First Name"
                              error={Boolean(errors.firstName)}
                              helperText={getErrorMessage(errors.firstName)}
                              data-test="signupFirstName"
                            />
                          </div>
                          <div className={clsx(signupStyles.formField, styles.half)}>
                            <Controller
                              as={TextField}
                              layout={layouts.signup}
                              name="lastName"
                              control={control}
                              label="Last Name"
                              error={Boolean(errors.lastName)}
                              helperText={getErrorMessage(errors.lastName)}
                              data-test="signupLastName"
                            />
                          </div>
                        </div>
                        <div className={signupStyles.formField}>
                          <Controller
                            as={TextField}
                            layout={layouts.signup}
                            name="email"
                            classes={invitationId ? customDisabledColor : {}}
                            control={control}
                            label="Email"
                            error={Boolean(errors.email)}
                            helperText={getErrorMessage(errors.email)}
                            disabled={!!invitationId}
                            data-test="signupEmailCompleteProfile"
                          />
                        </div>
                        <div className={signupStyles.formField}>
                          <Controller
                            as={PasswordField}
                            layout={layouts.signup}
                            name="password"
                            control={control}
                            label={invitationId ? 'New Password' : 'Password'}
                            error={Boolean(errors.password)}
                            helperText={getErrorMessage(errors.password)}
                            data-test="signupPassword"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className={signupStyles.sendButton}>
                  <Button
                    type="submit"
                    layout={layouts.signup}
                    disabled={signupSuccess}
                    size="large"
                    mode="dark"
                    data-test="profileSubmitButton"
                  >
                    Create Account
                  </Button>
                </div>
              </form>
            )}
          </div>
        </div>

        <MessageModal
          message={modal.message}
          open={modal.open}
          onConfirm={reopenForm}
          closeModal={reopenForm}
          type={modal.type}
        />
      </StepperContainer>
    </>
  );
};

const mapStateToProps = state => {
  return {
    isAuthorized: state.network.isAuthorized,
    signupSuccess: state.network.signupSuccess,
    signupFailure: state.network.signupFailure,
    signupStatusCode: state.network.signupStatusCode,
    signupError: state.network.signupError,
    isSigningUp: state.network.isSigningUp,
    isFirstTimeLogging: state.network.isFirstTimeLogging,
    username: state.user.username
  };
};

export default connect(mapStateToProps)(memo(CreateAccount));
