import { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { Paper } from '@material-ui/core';
import MarkdownHtml from 'studio/components/MarkdownHtml/MarkdownHtml';

import { useAutocompleteTooltipStyles, useTooltipStyles } from './styles';

const Q2QTooltip = forwardRef(
  (
    {
      children,
      tooltipForAutocomplete,
      title = '',
      description = '',
      placement = 'top',
      controlled = false,
      open = false,
      ...rest
    },
    ref
  ) => {
    const tooltipClasses = tooltipForAutocomplete ? useAutocompleteTooltipStyles() : useTooltipStyles();

    const [keepOpen, setKeepOpen] = useState(false);

    /* The description set in the span is HTML sanitized by the backend */
    const tooltipContent = (
      <Paper
        {...(controlled
          ? {
              onMouseEnter: () => setKeepOpen(true),
              onMouseLeave: () => setKeepOpen(false)
            }
          : {})}
        elevation={5}
        className={tooltipClasses.paper}
      >
        <h4>{title}</h4>
        {typeof description === 'string' ? <MarkdownHtml html={description} /> : description}
      </Paper>
    );

    return (
      <Tooltip
        ref={ref}
        title={tooltipContent}
        placement={placement}
        classes={{ tooltip: tooltipClasses.tooltip }}
        interactive
        {...(controlled ? { open: open || keepOpen } : {})}
        {...rest}
      >
        {children}
      </Tooltip>
    );
  }
);

export default Q2QTooltip;

Q2QTooltip.propTypes = {
  children: PropTypes.any,
  tooltipForAutocomplete: PropTypes.bool,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placement: PropTypes.string,
  controlled: PropTypes.bool
};
