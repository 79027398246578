import React, { useState, useEffect, useMemo, memo } from 'react';
import { connect } from 'react-redux';
import i18n from 'root/i18n';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import LoadingIcon from 'svg/v2/loading.svg';

import { setLanguage } from 'store/modules/user';

import { languageInfo } from 'config/constants';

import { useButtonStyles, useMenuItemStyles, useMenuIconStyles } from './LanguageSelect.styles';
import styles from 'components/sidebar/sidebar.scss';
import { embeddedMessageTypes, receiveMessageFromParentWindow } from 'root/hocs/withEmbedded';
import { useLocation } from 'react-router-dom';
import CountryFlag from 'components/shared/CountryFlag';

const LanguageSelect = ({ languages, language, loadingLanguage, dispatch }) => {
  const [value, setValue] = useState('');
  const [anchor, setAnchor] = useState(null);
  let { search } = useLocation();

  const buttonClasses = useButtonStyles();
  const menuItemClasses = useMenuItemStyles();
  const menuIconClasses = useMenuIconStyles();

  const formattedLanguages = useMemo(() => {
    if (languages?.length) {
      return languages.map(language => ({
        name: language.language,
        value: language.tag,
        initial: languageInfo[language.tag].initial,
        icon: <CountryFlag svgFlag={languageInfo[language.tag].svgFlag} />
      }));
    }
    return [];
  }, [languages]);

  const open = event => {
    setAnchor(event.currentTarget);
  };

  const close = () => setAnchor(null);

  const changeLanguage = newLanguage => {
    if (newLanguage !== value && languages?.find(language => language.tag === newLanguage)) {
      i18n.changeLanguage(newLanguage);
      setValue(newLanguage);
      dispatch(setLanguage(newLanguage));
    }
  };

  // if there is a language URL parameter, we use it to set the language
  const urlParams = new URLSearchParams(search);
  const languageParam = urlParams.get('lang');
  useEffect(() => {
    if (languageParam) {
      changeLanguage(languageParam);
    }
  }, [languageParam]);

  useEffect(() => {
    if (languages && languages.length && language) {
      setValue(language);
    }
  }, [languages, language]);

  useEffect(() => {
    receiveMessageFromParentWindow(embeddedMessageTypes.setLanguage, data => {
      const { language } = data;
      changeLanguage(language);
    });
  }, []);

  return (
    <>
      <Button
        classes={buttonClasses}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={open}
        data-test="languageSelect"
      >
        {loadingLanguage || !language ? <LoadingIcon className={styles.loadingIcon} /> : languageInfo[language].initial}
      </Button>
      <Menu
        anchorEl={anchor}
        keepMounted
        open={!!anchor}
        onClose={close}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
        transformOrigin={{ vertical: 'center', horizontal: 'left' }}
      >
        {formattedLanguages.map((item, index) => (
          <MenuItem
            classes={menuItemClasses}
            key={`language_item_${index}`}
            onClick={() => {
              changeLanguage(item.value);
              close();
            }}
            data-value={item.value}
          >
            {item.icon && <ListItemIcon classes={menuIconClasses}>{item.icon}</ListItemIcon>}
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const mapStateToProps = state => ({
  loadingLanguage: state.user.loadingLanguage,
  language: state.user.language,
  languages: state.user.languages
});

export default connect(mapStateToProps)(memo(LanguageSelect));
