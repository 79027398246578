import { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { boundMethod } from 'autobind-decorator';
import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import { withRouter } from 'react-router-dom';
import { veezooRoutes } from 'components/app/routes';
import { completeKnowledgeGraphTutorial, finishedTutorial } from 'store/modules/tutorial';
import { tutorialNames } from 'config/constants';
import Title from 'components/tutorial/Title';

const tutorialId = tutorialNames.KNOWLEDGE_GRAPH_TUTORIAL;

export class KnowledgeGraphTutorial extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tourCounter: 0,
      stepIndex: 0 // a controlled tour
    };
  }

  @boundMethod
  callback(tour) {
    const { action, type, index } = tour;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // update state to advance the tour
      this.setState({
        stepIndex: index + (action === ACTIONS.PREV ? -1 : 1)
      });
    }

    if (type === EVENTS.TOUR_END || type === EVENTS.TOOLTIP_CLOSE) {
      // update user preferences with completed tour flag
      this.props.dispatch(completeKnowledgeGraphTutorial());
      this.setState({
        tourCounter: this.state.tourCounter + 1,
        stepIndex: 0
      });
    }

    if (type === EVENTS.TOUR_END) {
      const isCompleted = action !== ACTIONS.SKIP;
      this.props.dispatch(finishedTutorial(tutorialId, isCompleted));
    }
  }

  render() {
    const { tourCounter, stepIndex } = this.state;
    const { t, generalOptions, runKnowledgeGraphTutorial, location } = this.props;
    const isInKnowledgeGraph = location.pathname === veezooRoutes.knowledgeGraph;
    const run = isInKnowledgeGraph && runKnowledgeGraphTutorial;

    const steps = [
      {
        target: '[data-knowledgegraph-tutorial="sidebar"]',
        placement: 'right',
        disableBeacon: true,
        locale: { next: 'Start' },
        content: t('tutorial.knowledge-graph.1'),
        title: <Title size="large" text={t('tutorial.knowledge-graph.1-title')} icon={<span>&#128075;</span>} />
      },
      {
        target: '[data-knowledgegraph-tutorial="show-visual-button"]',
        placement: 'right',
        disableBeacon: true,
        spotlightClicks: true,
        title: <Title size="large" text={t('tutorial.knowledge-graph.2-title')} icon={<span>&#128075;</span>} />,
        content: t('tutorial.knowledge-graph.2')
      },
      {
        target: '[data-knowledgegraph-tutorial="get-back"]',
        placement: 'right',
        disableBeacon: true,
        spotlightClicks: true,
        title: <Title size="large" text={t('tutorial.knowledge-graph.3-title')} icon={<span>&#128075;</span>} />,
        content: t('tutorial.knowledge-graph.3')
      }
    ];

    return (
      <Fragment>
        <Joyride
          {...generalOptions}
          ref="joyride"
          key={tourCounter}
          steps={steps}
          run={run}
          callback={this.callback}
          stepIndex={stepIndex}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    runKnowledgeGraphTutorial: state.tutorial.runKnowledgeGraphTutorial
  };
};

export default withTranslation('veezoo')(connect(mapStateToProps)(withRouter(KnowledgeGraphTutorial)));
