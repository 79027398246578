import clsx from 'clsx';
import { connect } from 'react-redux';

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import Button from 'components/buttons/Button';

import styles from './home.scss';

const MobileHeader = ({ logoFull, isMobileMenuOpen, toggleMobileMenu }) => (
  <div className={styles.newHeader}>
    <img src={logoFull} className={styles.logo} alt="logo" />
    <Button
      className={clsx(styles.menuIcon, isMobileMenuOpen ? styles.menuIconOpen : '')}
      onClick={() => toggleMobileMenu(!isMobileMenuOpen)}
    >
      <MenuRoundedIcon fontSize="large" />
    </Button>
  </div>
);

const mapStateToProps = state => ({
  logoFull: state.theme.themeDetails.assets.logoFull
});

export default connect(mapStateToProps)(MobileHeader);
