import { Component, createRef, Fragment } from 'react';
import { connect } from 'react-redux';
import Graph from 'react-graph-vis';
import { Trans, withTranslation } from 'react-i18next';
import { lighten } from '@material-ui/core/styles';

import SearchArea from 'components/knowledgeGraph/search/SearchArea';
import SearchResultsPanel from 'components/knowledgeGraph/search/SearchResultsPanel';
import InfoPanelPopover from './InfoPanelPopover';

import TextIcon from 'svg/knowledgeGraph/text.svg';
import InfoIcon from '@mui/icons-material/Info';

import { searchGraph } from 'store/modules/graph/graphSearchResults';
import { availableGroups, OntologyBoolean, OntologyDate } from 'store/utils/knowledgeGraphOntologyIcons';
import { fetchGraph, fetchGraphLayout, setEntitiesSidebar, updateNode } from 'store/modules/graph/graph';
import { cancelAllEditing, setIsEditingTitle } from 'store/modules/nodeEditor';

import { trackEvent } from 'utils/eventTracking';
import svgNode from 'utils/defineSvgShape';

import styles from './graph.scss';
import withEmbedded from 'root/hocs/withEmbedded';
import CreateRelationshipDialog from 'components/shared/CreateRelationshipDialog/CreateRelationshipDialog';

import {
  setEdgeStructure,
  setNodeStructure,
  shouldUsePhysics
} from 'components/knowledgeGraph/knowledgeGraphFunctions';
import { graphNodeTypes } from '../../config/constants';
import PageLoader from 'components/loaders/PageLoader';

const cursorStyles = {
  grabbing: 'grabbing',
  pointer: 'pointer',
  default: 'default'
};

const HighlightedShadow = color => ({
  enabled: true,
  color: color,
  size: 20,
  x: 0,
  y: 0
});

const DefaultShadow = {
  enabled: false
};

const effectsTimeInMiliseconds = 500;
const visAnimationConfig = { duration: effectsTimeInMiliseconds, easingFunction: 'easeInOutQuad' };

class KnowledgeGraph extends Component {
  constructor(props) {
    super(props);
    let graphState = {
      edges: setEdgeStructure(this.props.graph.edges),
      nodes: setNodeStructure(this.props.graph.nodes, this.props.graph.visualNodeLayouts, props.suggestionsForBadNames)
    };

    this.primaryColor = this.props.cssVars['--primary-color'];
    // fall back to the default secondary color since '--secondary-color' doesn't seem to be set when using the default theme
    this.secondaryColor = this.props.cssVars['--secondary-color'] || '#00b3cc';
    this.accentLightColor = this.props.cssVars['--accent-light-color'];

    this.canvasRef = createRef;
    this.expandFromOutside = null;
    this.timeout = null;
    this.keyDownTimeout = null;
    this.state = {
      // flag to delay rendering of the graph for a short time
      showGraph: false,
      graph: graphState,
      shouldUsePhysics: shouldUsePhysics(this.props.graph.visualNodeLayouts),
      graphSearchScrollCount: 0,
      activeSelectableElement: 0,
      selectedNode: null,
      shouldFocusOnInput: false,
      isFocusedOnSearch: false,
      popoverIsOpen: false,
      popoverPosition: { x: 0, y: 0 },
      canvasPositionOnScreen: { x: 0, y: 0 },
      currentNode: null,
      disableHover: false,
      // dragging related stuff
      isDraggingNode: false,
      draggingNodeId: null,
      closestNodeId: null,
      isDraggedNodeForeignKey: false,
      openRelationshipDialogueOnDragEnd: false,
      simulatedEdgeAndNode: null,
      shouldDrawRelationship: false
    };
  }

  renderNode = (ontoType, selected = false, unfocused = false, opacity) => {
    let nodeColor = (ontoType && availableGroups[ontoType]?.color) || this.primaryColor;
    let iconColor = '#ffffff';
    if (selected) {
      nodeColor = lighten(nodeColor, 0.3);
    } else if (unfocused) {
      nodeColor = this.accentLightColor;
      iconColor = '#bbbbbc';
    }
    return svgNode(nodeColor, opacity, availableGroups[ontoType]?.icon || null, iconColor);
  };

  setNetworkInstance = network => {
    this.network = network;
    this.resetCanvasPosition();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    let graphState = {
      edges: setEdgeStructure(nextProps.graph.edges),
      nodes: setNodeStructure(
        nextProps.graph.nodes,
        nextProps.graph.visualNodeLayouts,
        nextProps.suggestionsForBadNames
      )
    };
    this.setState({
      graph: graphState,
      shouldUsePhysics: shouldUsePhysics(nextProps.graph.visualNodeLayouts)
    });
  }

  componentDidMount() {
    // update the state (no-op) to make sure that 'componentDidUpdate' is called such that the graph gets shown
    // ('componentDidUpdate' isn't initially triggered when 'graph' is already loaded)
    this.setState({ showGraph: false });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.showGraph) {
      const { graph } = this.props;
      // when the graph is ready to be rendered, delay the rendering for a short time: without this wait, for some
      // reason the graph is sometimes wrongly positioned, off the center to the bottom right (this can be reproduced
      // by reloading the page a couple of times when showing the visual KG)
      if (graph && !graph.isLoadingGraph && !graph.isLoadingGraphLayout && graph.nodes) {
        setTimeout(() => this.setState({ showGraph: true }), 250);
      }
      // stop while the graph isn't rendered yet, to avoid errors
      return;
    }

    // resize once when unhiding the component
    if (prevProps.isHidden && !this.props.isHidden) {
      this.screenHasResized();
    }

    const { isSearching, highlighting, query } = this.props.graphSearchResults;
    if (!isSearching && query) {
      this.highlightNodes(highlighting || []);
    }
    if (!query) {
      this.unselectAllNodes();
    }

    const prevSimulatedEdge = prevState.simulatedEdgeAndNode?.edge;
    const prevSimulatedNode = prevState.simulatedEdgeAndNode?.node;
    const prevShouldDrawRelationship = prevState.shouldDrawRelationship;

    const simulatedEdge = this.state.simulatedEdgeAndNode?.edge;
    const simulatedNode = this.state.simulatedEdgeAndNode?.node;
    const shouldDrawRelationship = this.state.shouldDrawRelationship;

    const addEdgeAndHideNode = () => {
      const newEdge = this.network.body.data.edges.add([
        {
          label: 'via ' + this.state.fromNode.name + '.' + this.state.foreignKeyNode.name,
          font: { align: 'middle', vadjust: -10, color: this.secondaryColor, size: 12, strokeWidth: 0 },
          from: this.state.fromNode.id,
          to: this.state.toNode.id,
          // make the edge directed
          arrows: 'to',
          dashes: true,
          width: 2,
          color: { color: this.secondaryColor }
        }
      ])[0];

      // also hide the foreign key node, the toNode should get also a dashed border as above the edge
      this.network.body.data.nodes.update([
        { id: this.state.foreignKeyNode.id, hidden: true },
        {
          id: this.state.toNode.id,
          shadow: HighlightedShadow(this.secondaryColor)
        },
        {
          id: this.state.fromNode.id,
          shadow: HighlightedShadow(this.secondaryColor)
        }
      ]);

      this.setState({ simulatedEdgeAndNode: { edge: { id: newEdge }, node: this.state.foreignKeyNode } });
    };

    const removeEdgeAndShowNode = (edgeId, nodeId) => {
      this.network.body.data.edges.remove([edgeId]);
      this.network.body.data.nodes.update([
        { id: nodeId, hidden: false },
        { id: this.state.toNode.id, shadow: DefaultShadow },
        { id: this.state.fromNode.id, shadow: DefaultShadow }
      ]);
    };

    if (shouldDrawRelationship) {
      // it's the first time we draw something
      if (!prevShouldDrawRelationship) {
        addEdgeAndHideNode();
      }
      // it's not the first time, but the edge or the node changed
      else if (prevSimulatedEdge !== simulatedEdge && prevSimulatedEdge) {
        removeEdgeAndShowNode(prevSimulatedEdge.id, prevSimulatedNode.id);
        addEdgeAndHideNode();
      }
    }
    // we used to draw something, but now we don't
    else if (prevShouldDrawRelationship) {
      if (prevSimulatedEdge) {
        removeEdgeAndShowNode(prevSimulatedEdge.id, prevSimulatedNode.id);
        // potentially also remove the new one if it's different
        if (prevSimulatedEdge !== simulatedEdge) {
          removeEdgeAndShowNode(simulatedEdge.id, simulatedNode.id);
        }
      }
      this.setState({ simulatedEdgeAndNode: null });
    }
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.screenHasResized);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.screenHasResized);
  }

  resetCanvasPosition = () => {
    const mycanvas = document.getElementsByTagName('canvas');
    if (mycanvas.length > 0) {
      const canvasPosition = mycanvas[0].getBoundingClientRect();
      if (
        canvasPosition.x !== this.state.canvasPositionOnScreen.x ||
        canvasPosition.y !== this.state.canvasPositionOnScreen.y
      ) {
        this.setState({ canvasPositionOnScreen: { x: canvasPosition.x, y: canvasPosition.y } });
      }
    }
  };

  updateGraph = () => this.network?.redraw();

  // The screen is not likely to be resized too often, but if it does we reset the canvas position and
  // update the graph.
  screenHasResized = () => {
    // don't resize while the component is hidden, as this leads to wrong resizing
    if (!this.props.isHidden) {
      this.resetCanvasPosition();
      this.updateGraph();
    }
  };

  searchNodes = searchQueryText => {
    const queryTerm = searchQueryText;
    this.setState({
      graphSearchScrollCount: 0,
      activeSelectableElement: 0
    });
    trackEvent('KG Visual Mode Searched', { query: searchQueryText });
    this.props.dispatch(searchGraph(queryTerm, 0));
  };

  handleSearchInputChange = searchQueryText => {
    if (this.keyDownTimeout) {
      clearTimeout(this.keyDownTimeout);
    }
    this.network.unselectAll();
    this.keyDownTimeout = setTimeout(() => this.searchNodes(searchQueryText), 500);
  };

  getMoreData = () => {
    this.props.dispatch(searchGraph(this.props.graphSearchResults?.query, this.state.graphSearchScrollCount + 1));
    this.setState({
      graphSearchScrollCount: this.state.graphSearchScrollCount + 1,
      shouldFocusOnInput: true
    });
  };

  unselectAllNodes = () => {
    const visNodes = this.network.body.data.nodes;
    const visEdges = this.network.body.data.edges;

    const newNodeProperties = this.state.graph.nodes.map(node => ({ id: node.id, group: node.group }));
    const newEdgeProperties = this.state.graph.edges.map(edge => ({ id: edge.id, color: { opacity: 1 } }));

    visNodes.update(newNodeProperties);
    visEdges.update(newEdgeProperties);
  };

  highlightNodes = highlightNodes => {
    this.unselectAllNodes();

    const visNodes = this.network.body.data.nodes;
    const visEdges = this.network.body.data.edges;

    const newNodeProperties = this.state.graph.nodes.map(node => {
      const intensity = highlightNodes.find(hNode => hNode.uri === node.uri)?.intensity || null;

      return {
        intensity,
        id: node.id,
        group: intensity ? `${node.group}_${intensity}` : `${node.group}_unfocused`
      };
    });

    // update edges after search
    const newEdgeProperties = this.state.graph.edges.map(edge => {
      if (
        newNodeProperties.find(node => node.id === edge.from).intensity &&
        newNodeProperties.find(node => node.id === edge.to).intensity
      ) {
        let intensity = Math.min(
          newNodeProperties.find(node => node.id === edge.from).intensity,
          newNodeProperties.find(node => node.id === edge.to).intensity
        );
        return {
          id: edge.id,
          color: { opacity: intensity / 10 }
        };
      } else if (
        !newNodeProperties.find(node => node.id === edge.from).intensity ||
        !newNodeProperties.find(node => node.id === edge.to).intensity
      ) {
        return {
          id: edge.id,
          color: { opacity: 0.55 }
        };
      }
    }, this);

    visNodes.update(newNodeProperties);
    visEdges.update(newEdgeProperties);
  };

  openSearchPanel = () => {
    this.setState({
      isFocusedOnSearch: true,
      popoverIsOpen: false,
      activeSelectableElement: 0
    });
  };

  closeSearchPanel = () =>
    this.setState({
      isFocusedOnSearch: false,
      shouldFocusOnInput: false
    });

  calculatePopoverPositionOnScreen = (node, nodePositionInCanvas) => {
    const popoverDesiredPosition = {
      x: nodePositionInCanvas.x + node.size,
      y: nodePositionInCanvas.y
    };

    const popoverPositionInDOM = this.network.canvasToDOM(popoverDesiredPosition);
    return {
      x: Math.floor(popoverPositionInDOM.x) + this.state.canvasPositionOnScreen.x,
      y: Math.floor(popoverPositionInDOM.y) + this.state.canvasPositionOnScreen.y
    };
  };

  focusNode = selectedNode => {
    this.closeSearchPanel();
    let currentNode = this.state.graph.nodes.find(node => node.uri === selectedNode.uri);
    this.setState({ selectedNode: currentNode.id, disableHover: true });

    const nodePositionInCanvas = this.network.getPositions(currentNode.id)[currentNode.id];

    this.network.moveTo({
      position: nodePositionInCanvas,
      scale: 1,
      animation: visAnimationConfig
    });
    this.network.selectNodes([currentNode.id]);

    setTimeout(() => {
      const popoverPositionOnScreen = this.calculatePopoverPositionOnScreen(currentNode, nodePositionInCanvas);
      this.openPopover(popoverPositionOnScreen, currentNode);
      this.props.dispatch(setIsEditingTitle(true));
      this.expandFromOutside(true);
      this.setState({ disableHover: false });
    }, effectsTimeInMiliseconds);
  };

  focusEntityNode = ({ entity, node }) => {
    this.closeSearchPanel();
    const uniqueId = `node_${node.uri}_entity_${entity.uri}`;

    this.props.dispatch(
      setEntitiesSidebar({
        uniqueId,
        isOpen: true,
        name: node.name,
        uri: node.uri,
        ontologyType: node.ontologyType,
        forcedOpenUri: entity.uri,
        inputField: entity.name,
        preventFetch: true,
        uniqueValues: [entity],
        uniqueValuesTotalCount: 1
      })
    );
  };

  handleKeyDown = $event => {
    if ($event.key === 'ArrowUp' || $event.key === 'ArrowDown' || $event.key === 'Enter') {
      if (this.props.graphSearchResults?.query && Object.keys(this.props.graphSearchResults).length) {
        $event.preventDefault();
        let graphSearchResults = {
          classes: this.props.graphSearchResults.classes || [],
          literals: this.props.graphSearchResults.literals || [],
          entities: this.props.graphSearchResults.entities || {}
        };
        let commonLength =
          graphSearchResults.classes.length +
          graphSearchResults.literals.length +
          (graphSearchResults?.entities?.results?.length || 0);
        if ($event.key === 'ArrowUp') {
          this.setState(prevState => {
            let count = prevState.activeSelectableElement - 1;
            if (count !== 0 && count !== -1) {
              return { activeSelectableElement: count };
            }
          });
          if (this.state.activeSelectableElement === -1) {
            document.getElementById('showMoreButton').style.opacity = 1;
            this.setState({ activeSelectableElement: commonLength });
          }
        } else if ($event.key === 'ArrowDown') {
          if (this.state.activeSelectableElement === -1) return;
          this.setState(prevState => {
            let count = prevState.activeSelectableElement + 1;
            if (prevState.activeSelectableElement !== commonLength) {
              return { activeSelectableElement: count };
            }
          });
          if (this.state.activeSelectableElement === commonLength) {
            document.getElementById('showMoreButton').style.opacity = 0.3;
            this.setState({ activeSelectableElement: -1 });
          }
        } else if ($event.key === 'Enter') {
          if (this.state.activeSelectableElement) {
            if (this.state.activeSelectableElement === -1) {
              document.getElementById('showMoreButton').style.opacity = 1;
              this.getMoreData();
              trackEvent('KG Visual Mode Searched');
              this.setState({ activeSelectableElement: commonLength + 1 });
            }
            document.activeElement.blur();
          }
        }
      }
    } else {
      // Here's what happens when the user is typing, and it is not Enter nor any arrows.
    }
  };

  openPopover = ({ x, y }, node) => {
    this.setState({ popoverPosition: { x, y }, popoverIsOpen: true, currentNode: node });
  };

  closePopover = () => {
    this.props.dispatch(cancelAllEditing());
    this.timeout = setTimeout(() => {
      this.setState({ popoverIsOpen: false });
    }, 200);
  };

  render() {
    const { isEmbedded, hideSearchArea, t } = this.props;
    const IconComponent = availableGroups[(this.state.currentNode?.ontologyType)]?.componentIcon || TextIcon;

    const ontologyTypeTranslationKey =
      this.state.currentNode?.ontologyType && availableGroups[(this.state.currentNode?.ontologyType)]?.translationKey;

    const subtitleText = ontologyTypeTranslationKey && t(`ontology.${ontologyTypeTranslationKey}`);

    const groups = {};

    Object.keys(availableGroups).forEach(group => {
      groups[group] = {
        image: {
          selected: this.renderNode(group, true),
          unselected: this.renderNode(group, false)
        }
      };

      groups[`${group}_unfocused`] = {
        image: {
          selected: this.renderNode(group, true, true),
          unselected: this.renderNode(group, false, true, 0.95)
        }
      };

      [...Array(10).keys()].forEach(intensity => {
        groups[`${group}_${intensity}`] = {
          image: {
            selected: this.renderNode(group, true, false),
            unselected: this.renderNode(group, false, false, intensity / 9)
          }
        };
      });
    });
    const options = {
      width: '100%',
      height: '100%',
      edges: {
        arrows: { to: { enabled: false } },
        color: { color: '#999' },
        // makes it more readable when we need to use physics (i.e. usually when a lot of nodes and the layouting times out in the backend)
        length: this.state.shouldUsePhysics ? 200 : undefined,
        font: { align: 'middle', color: '#999', face: 'Lexend', size: 12 }
      },
      nodes: {
        shape: 'circularImage',
        image: {
          selected: this.renderNode(null, true),
          unselected: this.renderNode(null, false)
        },
        shapeProperties: { interpolation: false },
        font: { color: '#333', face: 'Lexend' },
        shadow: DefaultShadow,
        color: {
          background: '#eff0f1'
        }
      },
      interaction: {
        hover: true,
        multiselect: true,
        hoverConnectedEdges: false
      },
      groups,
      physics: {
        enabled: this.state.shouldUsePhysics
      }
    };

    const events = {
      click: ({ nodes }) => {
        if (this.state.disableHover) return;
        this.closeSearchPanel();
        document.activeElement.blur();
        if (nodes.length) {
          this.network.selectNodes([nodes[0]]);
          this.setState({ selectedNode: nodes[0] });
          if (this.expandFromOutside) {
            this.expandFromOutside(true);
          }
        } else {
          this.setState({ selectedNode: null });
          this.closePopover();
        }
      },
      blurNode: () => {
        this.network.selectNodes(this.state.selectedNode ? [this.state.selectedNode] : []);
        this.network.canvas.body.container.style.cursor = cursorStyles.default;
        this.closePopover();
      },
      hoverNode: event => {
        if (this.state.disableHover) return;
        clearTimeout(this.timeout);

        const nodeId = event.node;
        const currentNode = this.state.graph.nodes.find(n => n.id === nodeId);
        const nodePositionInCanvas = this.network.getPositions(nodeId)[nodeId];
        const popoverPositionOnScreen = this.calculatePopoverPositionOnScreen(currentNode, nodePositionInCanvas);

        this.openPopover(popoverPositionOnScreen, currentNode);
        this.network.canvas.body.container.style.cursor = cursorStyles.pointer;
        this.network.selectNodes(this.state.selectedNode ? [this.state.selectedNode, nodeId] : [nodeId]);
      },
      dragStart: e => {
        this.setState({ isDraggingNode: e.nodes.length > 0, disableHover: true, draggingNodeId: e.nodes[0] });
        this.closePopover();
        this.network.canvas.body.container.style.cursor = cursorStyles.grabbing;
      },
      dragging: e => {
        if (!this.state.isDraggingNode) return;

        this.network.canvas.body.container.style.cursor = cursorStyles.grabbing;
        const draggingNodeId = this.state.draggingNodeId;
        const draggedNodePosition = this.network.getPositions([draggingNodeId])[draggingNodeId];

        const draggedNode = this.state.graph.nodes.find(n => n.id === draggingNodeId);

        let closestNodeId = null;
        let closestDistance = Infinity;

        // check if the dragged node is a potential foreign key by checking if it has only one edge
        const draggedNodeEdges = this.state.graph.edges.filter(
          edge => edge.from === draggingNodeId || edge.to === draggingNodeId
        );

        const canBeUsedInRelationship = node =>
          node?.ontologyType !== OntologyDate && node?.ontologyType !== OntologyBoolean; // we don't consider dates nor booleans as foreign keys
        const canDraggedNodeBeUsedInRelationship = canBeUsedInRelationship(draggedNode);
        const isDraggedNodeForeignKey = draggedNodeEdges.length === 1 && canDraggedNodeBeUsedInRelationship;

        // Iterate over all nodes to find the closest
        const allNodes = this.network.body.data.nodes.get();

        // if the dragged node is a foreign key, the target must be a class
        // if the dragged node is not a foreign key, it will be a class, and the target nodes must be potential foreign keys
        const targetNodes = isDraggedNodeForeignKey
          ? allNodes.filter(node => node.type === graphNodeTypes.CLASS)
          : allNodes.filter(node => canBeUsedInRelationship(node));

        targetNodes.forEach(node => {
          if (node.id !== draggingNodeId) {
            const nodePosition = this.network.getPositions([node.id])[node.id];
            const distance = Math.sqrt(
              Math.pow(nodePosition.x - draggedNodePosition.x, 2) + Math.pow(nodePosition.y - draggedNodePosition.y, 2)
            );

            if (distance < closestDistance) {
              closestDistance = distance;
              closestNodeId = node.id;
            }
          }
        });

        // now we need to find out if the node we are dragging is potentially a foreign key
        // i.e. it's possible to drop Customer ID on Customer -or- it's possible to drop Customer on Customer ID
        const closestNode = this.state.graph.nodes.find(n => n.id === closestNodeId);
        const foreignKeyNode = isDraggedNodeForeignKey ? draggedNode : closestNode;

        // from the foreign key node we need to find the node it's connected to
        const foreignKeyEdge = this.state.graph.edges.find(
          edge => edge.from === foreignKeyNode?.id || edge.to === foreignKeyNode?.id
        );
        const fromNode = this.state.graph.nodes.find(
          node => node.id === (foreignKeyEdge?.from === foreignKeyNode?.id ? foreignKeyEdge?.to : foreignKeyEdge?.from)
        );
        const toNode = isDraggedNodeForeignKey ? closestNode : draggedNode;

        const shouldDrawRelationship =
          closestDistance < 30 && canDraggedNodeBeUsedInRelationship && this.props.hasWritePermission;
        const openRelationshipDialogueOnDragEnd = shouldDrawRelationship && this.props.hasWritePermission;
        this.setState({
          fromNode: fromNode,
          toNode,
          foreignKeyNode,
          draggedNode,
          openRelationshipDialogueOnDragEnd,
          isDraggedNodeForeignKey,
          shouldDrawRelationship
        });
      },
      dragEnd: () => {
        this.setState(prevState => {
          return {
            isDraggingNode: false,
            draggingNodeId: prevState.openRelationshipDialogueOnDragEnd ? prevState.draggingNodeId : null,
            disableHover: false,
            shouldDrawRelationship: false
          };
        });
        this.network.canvas.body.container.style.cursor = 'default';
      }
    };

    const { foreignKeyNode, fromNode, toNode, draggedNode } = this.state;

    const shouldShowSearchResultsPanel = this.state.isFocusedOnSearch && !isEmbedded;
    const searchResultsPanel = (
      <SearchResultsPanel
        focusClassNode={this.focusNode}
        focusEntityNode={this.focusEntityNode}
        focusLiteralNode={this.focusNode}
        focusMeasureNode={this.focusNode}
        activeSelectableElement={this.state.activeSelectableElement}
        searchQueryText={this.props.graphSearchResults?.query || ''}
        closeSearchPanel={this.closeSearchPanel}
        getMoreData={this.getMoreData}
        graphSearchResults={this.props.graphSearchResults}
      />
    );

    const shouldShowDraggingInfo =
      this.props.hasWritePermission && this.state.isDraggingNode && foreignKeyNode && toNode && fromNode;
    const draggingInfo = shouldShowDraggingInfo && (
      <div className={styles.draggingNodeInfo}>
        <InfoIcon className={styles.infoIcon} />
        {this.state.openRelationshipDialogueOnDragEnd ? (
          <Trans
            i18nKey="visual-kg.create-relationship-from-xx-to-yy"
            values={{
              from: fromNode.name,
              to: toNode.name,
              foreignKeyNode: fromNode.name + '.' + foreignKeyNode.name
            }}
            parent={Fragment}
            components={{
              span: <span className={styles.draggingNodeInfoText} />
            }}
          />
        ) : this.state.isDraggedNodeForeignKey ? (
          <Trans
            i18nKey="visual-kg.if-xx-is-foreign-key-drag-to-yy"
            values={{ foreignKeyNode: fromNode.name + '.' + foreignKeyNode.name }}
            parent={Fragment}
            components={{
              span: <span className={styles.draggingNodeInfoText} />
            }}
          />
        ) : (
          <Trans
            i18nKey="visual-kg.if-yy-is-target-of-foreign-key-drag-to-foreign-key"
            values={{ draggedNode: draggedNode.name }}
            parent={Fragment}
            components={{
              span: <span className={styles.draggingNodeInfoText} />
            }}
          />
        )}
      </div>
    );
    const shouldShowSearchArea = this.state.showGraph && !shouldShowDraggingInfo && !hideSearchArea;
    const searchArea = (
      <SearchArea
        label="mainSearch"
        shouldFocusOnInput={this.state.shouldFocusOnInput}
        query={this.props.graphSearchResults?.query || ''}
        onFocus={this.openSearchPanel}
        onKeyDown={this.handleKeyDown}
        onChange={this.handleSearchInputChange}
        shouldShowCloseButton={!isEmbedded}
      />
    );

    return (
      <div className={styles.container}>
        {shouldShowSearchResultsPanel && searchResultsPanel}
        <div className={styles.graphComponent}>
          {shouldShowDraggingInfo && draggingInfo}
          {shouldShowSearchArea && searchArea}
          <div
            data-knowledgegraph-tutorial="step-2"
            className={isEmbedded ? styles.graphEmbedded : styles.graph}
            onMouseLeave={this.closePopover}
          >
            <InfoPanelPopover
              PopoverProps={{
                x: this.state.popoverPosition.x,
                y: this.state.popoverPosition.y,
                open: this.state.popoverIsOpen
              }}
              ContentProps={{
                node: this.state.graph.nodes.find(item => item.id === this.state.currentNode?.id) || null,
                subtitleIcon: IconComponent,
                nodeType: this.state.currentNode?.type,
                expandFromOutside: setIsExpanded => (this.expandFromOutside = setIsExpanded),
                subtitleText,
                updateNode,
                hideStudioLink: this.props.hideStudioLink,
                hideEntitiesSearch: this.props.hideEntitiesSearch
              }}
            />
            {this.state.showGraph ? (
              <Graph
                ref={this.canvasRef}
                id="graphCanvas"
                graph={this.state.graph}
                options={options}
                events={events}
                getNetwork={this.setNetworkInstance}
              />
            ) : (
              <PageLoader message="Loading Knowledge Graph..." />
            )}
          </div>
        </div>

        <CreateRelationshipDialog
          open={
            this.props.hasWritePermission &&
            foreignKeyNode &&
            toNode &&
            this.state.openRelationshipDialogueOnDragEnd &&
            !this.state.isDraggingNode
          }
          fromNode={fromNode}
          toNode={toNode}
          foreignKeyNode={foreignKeyNode}
          onClose={() => {
            this.props.dispatch(fetchGraphLayout());
            this.props.dispatch(fetchGraph());
            this.setState({ openRelationshipDialogueOnDragEnd: false });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  knowledgeGraphId: state.knowledgeGraphMeta.meta.id,
  hasWritePermission: state.knowledgeGraphMeta.meta.hasWritePermission,
  graphSearchResults: state.graphSearchResults,
  cssVars: state.theme.themeDetails.cssVars,
  suggestionsForBadNames: state.recipes.suggestionsForBadNames
});

export default withTranslation('veezoo')(connect(mapStateToProps)(withEmbedded(KnowledgeGraph)));
