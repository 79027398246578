import { makeStyles } from '@material-ui/core/styles';

export const useGradientBoxStyles = makeStyles({
  root: ({ isLoading, value }) => ({
    maxWidth: '980px',
    width: '100%',
    position: 'relative',
    background: isLoading
      ? `repeating-conic-gradient(from ${value}deg, var(--primary-color) 0%, var(--secondary-color) 50%, var(--primary-color) 100%)`
      : 'var(--accent-normal-color)',
    borderRadius: '6px',
    padding: '2px'
  })
});

export const useDialogTextStyles = makeStyles({
  root: {
    fontSize: '14px !important',
    paddingBottom: '10px'
  }
});

export const useModifyVisualizationStyles = makeStyles({
  root: {
    display: props => (props.show ? 'block' : 'none'),
    background: '#f8f8f8',
    margin: '0 -25px',
    padding: '13px 0px 10px 0px',
    position: 'relative',
    borderTop: '1px solid var(--accent-normal-color)'
  },
  inputField: {
    color: props => (props.isFetchingVisualizationModification ? 'var(--accent-normal-color)' : 'black'),
    borderRight: 'none',
    height: 'auto',
    width: '100%',
    border: 'none',
    padding: '5px 5px 5px 11px',
    borderRadius: '4px',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0'
  },
  centerText: {
    color: 'grey',
    fontWeight: 400
  },
  outerDiv: {
    margin: '0 25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    rowGap: '12px'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center'
  }
});

export const buttonClasses = {
  editOptions: {
    color: 'grey',
    fontSize: '11px',
    background: 'white',
    flex: '1 0 auto',
    height: 'auto',
    borderRadius: '0'
  },
  confirmArrow: {
    height: 'auto',
    background: 'white',
    borderRadius: '0',
    padding: '10px',
    width: '40px',
    '&&:disabled': {
      backgroundColor: 'white',
      '& path': {
        fill: 'var(--light-gray)'
      }
    }
  },
  close: { position: 'absolute', top: '5px', right: '5px' }
};
