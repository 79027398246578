import classnames from 'classnames';
import styles from './table-plot.scss';
import { useExpanded, useBlockLayout, useResizeColumns, useTable, usePagination, useSortBy } from 'react-table';
import { useCallback, useLayoutEffect, useMemo, useRef, useState, useEffect } from 'react';
import { createResizeObserver } from '../../utils/domObservers';

const MIN_WIDTH = 45;

/**
 * This function computes the initial rendered column widths based on the computed minimum column widths (in TablePlot.js)
 * and the available table width. If the total initial width is smaller than the available table width (from DOM), the columns are
 * equally stretched to fill the available table width. If there are columnAccessorsToStretch passed, only those are stretched.
 * Requires columns to have a width property.
 * E.g. the visual bar for number columns. If no columnAccessorsToStretch are passed, all columns are stretched.
 */
const computeInitialColumnWidths = (columns, availableTableWidth, columnAccessorsToStretch) => {
  const totalInitialWidth = columns.reduce((acc, curr) => acc + curr.width, 0);
  // create object {columnId1: columnWidth1, columnId2: columnWidth2, ...}
  return columns
    .map(column => {
      if (totalInitialWidth < availableTableWidth) {
        const widthToDistribute = availableTableWidth - totalInitialWidth;
        if (columnAccessorsToStretch.length > 0) {
          // if there are number columns, only stretch those
          if (columnAccessorsToStretch.includes(column.accessor)) {
            const columnWidth = Math.floor(column.width + widthToDistribute / columnAccessorsToStretch.length);
            return { [column.accessor]: columnWidth };
          }
        } else {
          // stretch columns to fill the available space, make sure every pixel is used when summing over new columns
          const columnWidth = Math.floor(column.width + widthToDistribute / columns.length);
          return { [column.accessor]: columnWidth };
        }
      }
      return { [column.accessor]: column.width };
    })
    .reduce((acc, curr) => ({ ...acc, ...curr }), {}); // flatten newColumnWidths into one object;
};

const ReactTableUI = ({
  className = '',
  data,
  columns,
  getColumnProps,
  disableResizing = false,
  loading = false,
  SubComponent,
  PaginationComponent,
  defaultPageSize,
  columnAccessorsToStretch = [],
  setIsHorizontalScrollbarVisible,
  setTablePaginationHeight,
  paginationSpacerHeight,
  ...props
}) => {
  // used to determine how much space the pagination component takes up, to optimize the number of rows shown
  // the pagination component takes up more space when the widget width is small enough
  const tablePaginationRef = useRef(null);
  useEffect(() => {
    // in a normal table answer (i.e. not a widget), we don't have a setter since we don't care about the pagination height
    // in this case, skip the useEffect
    if (setTablePaginationHeight) {
      const resizeObserver = createResizeObserver(rect => {
        // only set the height if the height is non-zero, it could be zero if we temporarily hide it (e.g. kg visual mode)
        if (rect.height > 0) {
          setTablePaginationHeight(rect.height);
        }
      });
      if (tablePaginationRef.current) {
        resizeObserver.observe(tablePaginationRef.current);
      }
      return () => resizeObserver.disconnect();
    }
  }, []);

  // used to compute the initial column widths in case we don't use the full available width
  const [availableTableWidth, setAvailableTableWidth] = useState(0);
  const containerRef = useRef(null);
  const updateAvailableTableWidth = () => {
    if (containerRef.current) {
      // sometimes we have a fractional width, so we round it down to the nearest integer
      // otherwise we would render a horizontal scrollbar
      setAvailableTableWidth(Math.floor(containerRef.current.getBoundingClientRect().width));
    }
  };

  // Whenever the browser window is resized, we need to update the available width for the table.
  useLayoutEffect(() => {
    updateAvailableTableWidth();
    window.addEventListener('resize', updateAvailableTableWidth);

    return () => {
      window.removeEventListener('resize', updateAvailableTableWidth);
    };
  }, []);

  const defaultColumn = useMemo(
    () => ({
      minWidth: MIN_WIDTH // this avoids making columns too small leading to layout issues (e.g. introducing another scrollbar)
    }),
    []
  );

  useEffect(() => {
    const totalInitialWidth = columns.reduce((acc, curr) => acc + curr.width, 0);
    if (setIsHorizontalScrollbarVisible) {
      setIsHorizontalScrollbarVisible(totalInitialWidth > availableTableWidth);
    }
  }, [columns, availableTableWidth, setIsHorizontalScrollbarVisible]);

  // Logic to compute the column widths in case we don't use the full available width
  const resizeColumns = useCallback(
    state => {
      const initalColumnWidths = computeInitialColumnWidths(columns, availableTableWidth, columnAccessorsToStretch);
      let userResizedWidths = state.columnResizing.columnWidths;
      // make sure all user resized widths are at least MIN_WIDTH, if not, set them to MIN_WIDTH
      userResizedWidths = Object.entries(userResizedWidths).reduce((acc, [key, value]) => {
        if (value < MIN_WIDTH) {
          return { ...acc, [key]: MIN_WIDTH };
        }
        return { ...acc, [key]: value };
      }, {});
      // merge the initial widths with the user resized widths
      const newColumnWidths = { ...initalColumnWidths, ...userResizedWidths };
      const totalColumnWidth = Object.values(newColumnWidths).reduce((acc, curr) => acc + curr, 0);

      // if the new column widths are smaller than the available table width, then we add the missing width to the last column
      if (totalColumnWidth < availableTableWidth && columns.length > 0) {
        const lastColumn = columns[columns.length - 1];
        newColumnWidths[lastColumn.accessor] =
          newColumnWidths[lastColumn.accessor] + (availableTableWidth - totalColumnWidth);
      }

      return { ...state, columnResizing: { ...state.columnResizing, columnWidths: newColumnWidths } };
    },
    [columns, availableTableWidth, columnAccessorsToStretch]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    totalColumnsWidth,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state,
    setPageSize,
    footerGroups
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      SubComponent,
      initialState: { pageSize: defaultPageSize, pageIndex: 0 },
      defaultCanSort: props.sortable,
      useControlledState: state => {
        return useMemo(() => resizeColumns(state), [state, columns, availableTableWidth]);
      }
    },
    useBlockLayout,
    useSortBy,
    useResizeColumns,
    useExpanded,
    usePagination
  );

  const { pageIndex, pageSize } = state;

  // The number of rows (`pageSize`) is cached in the table's state,
  // so we need to make sure to update it whenever the `defaultPageSize` changes.
  useEffect(() => {
    setPageSize(defaultPageSize);
  }, [defaultPageSize]);

  const baseTableBodyProps = getTableBodyProps();
  const tableBodyProps = {
    ...baseTableBodyProps,
    // add to baseTableBodyProps.style another property width = totalColumnsWidth
    style: {
      ...baseTableBodyProps.style,
      width: totalColumnsWidth
    }
  };

  return (
    //Applying the table props
    <div className={classnames(className, 'ReactTable')} ref={containerRef}>
      <div {...getTableProps()} className="rt-table">
        <div className="rt-thead -header">
          {// Looping over the header rows
          headerGroups.map(headerGroup => (
            //Applying the header row props
            // eslint-disable-next-line react/jsx-key
            <div {...headerGroup.getHeaderGroupProps()} className="rt-tr">
              {//Looping over the headers in each row
              headerGroup.headers.map(column => {
                const baseColumnProps = column.getHeaderProps(getColumnProps && getColumnProps(column));
                const columnProps = {
                  ...baseColumnProps,
                  // add to baseRowProps.style another property flex-basis = baseRowProps.style.width
                  style: {
                    ...baseColumnProps.style,
                    flexBasis: baseColumnProps.style.width
                  }
                };
                return (
                  // eslint-disable-next-line react/jsx-key
                  <div
                    className={
                      'rt-th rt-resizable-header' +
                      (column.isSorted ? (column.isSortedDesc ? ' -sort-desc' : ' -sort-asc') : '')
                    }
                    {...columnProps}
                  >
                    <div className="rt-resizable-header-content" {...column.getSortByToggleProps()}>
                      {column.render('Header')}
                    </div>
                    {!disableResizing && <div {...column.getResizerProps()} className="rt-resizer" />}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        {/* Applying the table body props*/}
        <div {...tableBodyProps} className="rt-tbody">
          {// show a message if no rows are found (and columns are around)
          page.length === 0 && !loading && columns.length > 0 && (
            <div className={classnames('rt-tr')}>
              <div className={classnames('rt-td')}>
                <b>No rows found</b>
              </div>
            </div>
          )}
          {//Loopting over the table rows
          page.map((row, ind) => {
            //Preparing row for rendering
            prepareRow(row);
            return (
              // eslint-disable-next-line react/jsx-key
              <div className={classnames('rt-tr', ind % 2 === 0 ? '-odd' : '-even')} {...row.getRowProps()}>
                {//Looping over the row cells
                row.cells.map(cell => {
                  const baseCellProps = cell.getCellProps(getColumnProps && getColumnProps(cell.column));
                  const cellProps = {
                    ...baseCellProps,
                    // add to baseRowProps.style another property flex-basis = baseRowProps.style.width
                    style: {
                      ...baseCellProps.style,
                      flexBasis: baseCellProps.style.width
                    }
                  };
                  return (
                    //Applying the cell props and rendering the cell content
                    // eslint-disable-next-line react/jsx-key
                    <div className={classnames('rt-td', cell.column.className)} {...cellProps}>
                      {cell.render('Cell')}
                    </div>
                  );
                })}
                {/* Checking row expansion */}
                {row.isExpanded ? SubComponent({ row }) : null}
              </div>
            );
          })}
        </div>
        {// only render footer if we have a column with a footer
        columns.some(c => c.Footer) && (
          <div className="rt-tfoot">
            {footerGroups.map(group => (
              // eslint-disable-next-line react/jsx-key
              <div className="rt-tr" {...group.getFooterGroupProps()}>
                {group.headers.map(column => {
                  const baseFooterProps = column.getFooterProps();
                  const footerProps = {
                    ...baseFooterProps,
                    // add to baseRowProps.style another property flex-basis = baseRowProps.style.width
                    style: {
                      ...baseFooterProps.style,
                      flexBasis: baseFooterProps.style.width
                    }
                  };
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <div className="rt-td" {...footerProps}>
                      {column.render('Footer')}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        )}
      </div>
      {/* Checking if pagination is required */}
      {PaginationComponent ? (
        <div className="pagination-bottom" ref={tablePaginationRef}>
          <PaginationComponent
            className="rt-pagination"
            {...props}
            rowCount={data?.length || 0}
            page={pageIndex}
            pageSize={pageSize}
            pages={pageCount}
            onPageChange={gotoPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            setPageSize={setPageSize}
            showPageJump={true}
            spacerHeight={paginationSpacerHeight}
          />
        </div>
      ) : null}
      {/* Fill in the gap to prevent blank space in the bottom */}
      {paginationSpacerHeight > 0 && (
        <div style={{ height: paginationSpacerHeight }} className={styles.paginationSpacer} />
      )}
      {/*Loading overlay*/}
      <div className={classnames('-loading', loading ? '-active' : '')}>
        <div className="-loading-inner">Loading...</div>
      </div>
    </div>
  );
};

export default ReactTableUI;
