import uuid1 from 'uuid/v1';

import { authFetchGet, authFetchPost } from 'store/utils/authFetch';
import {
  fetchComplementary,
  CHATMESSAGE_VISUALIZATION_COUNT_REQUEST,
  CHATMESSAGE_VISUALIZATION_COUNT_SUCCESS,
  replaceTotalCountPlaceholder,
  CHATMESSAGE_VISUALIZATION_COUNT_FAILURE
} from 'store/modules/chat-messages';

export const PARTIAL_ANSWER_REQUEST = 'PARTIAL_ANSWER_REQUEST';
export const PARTIAL_ANSWER_SUCCESS = 'PARTIAL_ANSWER_SUCCESS';
export const PARTIAL_ANSWER_FAILURE = 'PARTIAL_ANSWER_FAILURE';

export const cancelPartialAnswer = (interpretationId, answerId) => {
  return dispatch => {
    return authFetchPost(
      `/answers/partials/${answerId}/${interpretationId}?sessionId=${window.veezoo.sessionId}`,
      dispatch
    )
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
      })
      .catch(error => console.log('Error cancelling query:', error));
  };
};

export const fetchPartialAnswer = (interpretationId, answerId, mainAnswerMessageId) => {
  return dispatch => {
    if (interpretationId) {
      return authFetchGet(
        '/answers/partials/' + answerId + '/' + interpretationId + '?sessionId=' + window.veezoo.sessionId,
        dispatch
      )
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            dispatch({
              type: PARTIAL_ANSWER_FAILURE,
              answerId,
              errorMessage: response.statusText,
              isUnauthorized: response.status === 403
            });
            throw Error(response.statusText);
          }
        })
        .then(json => {
          dispatch({
            type: PARTIAL_ANSWER_SUCCESS,
            message: {
              ...json,
              id: uuid1()
            }
          });
          json.complementaryIds.forEach(c => {
            dispatch(fetchComplementary(c, json.answerId, json.questionId, mainAnswerMessageId));
          });
          return json;
        })
        .catch(error => console.log('ERROR', error));
    } else {
      console.error('No interpretation ID given, this partial answer is broken');
    }
  };
};

export const partialAnswers = (state = [], action) => {
  switch (action.type) {
    case PARTIAL_ANSWER_REQUEST:
      return state;

    case PARTIAL_ANSWER_SUCCESS: {
      const answer = {
        ...action.message.answer,
        ...(action.message.answer?.isError && {
          hasFailed: true,
          errorMessage: action.message.answer.textAnswer
        })
      };
      return [...state, answer];
    }

    case PARTIAL_ANSWER_FAILURE: {
      const indexToReplace = state.findIndex(answer => answer.id === action.answerId);
      if (indexToReplace !== -1) {
        let newState = [...state];
        newState[indexToReplace] = {
          ...newState[indexToReplace],
          hasFailed: true,
          errorMessage: action.errorMessage,
          isUnauthorized: action.isUnauthorized
        };
        return newState;
      } else {
        return state;
      }
    }

    // CHATMESSAGE_VISUALIZATION_COUNT_* state updates on Complementaries are handled in chat-messages.
    case CHATMESSAGE_VISUALIZATION_COUNT_REQUEST: {
      return state.map(item => {
        if (item.partialAnswerId === action.partialAnswerId) {
          return {
            ...item,
            visualizations: item.visualizations.map(visualization => ({
              ...visualization,
              fetchingTotalCount: true
            }))
          };
        }
        return item;
      });
    }

    case CHATMESSAGE_VISUALIZATION_COUNT_SUCCESS: {
      return state.map(item => {
        if (item.partialAnswerId === action.partialAnswerId) {
          return {
            ...item,
            visualizations: item.visualizations.map(visualization => ({
              ...visualization,
              fetchingTotalCount: false,
              summary: replaceTotalCountPlaceholder(visualization.summary, action.rowCount)
            }))
          };
        }
        return item;
      });
    }

    case CHATMESSAGE_VISUALIZATION_COUNT_FAILURE: {
      return state.map(item => {
        if (item.partialAnswerId === action.partialAnswerId) {
          return {
            ...item,
            visualizations: item.answer.visualizations.map(visualization => ({
              ...visualization,
              fetchingTotalCount: false
            }))
          };
        }
        return item;
      });
    }

    default:
      return state;
  }
};
