import { post, postWithBlobResponse } from 'services/http';

const getTableAnswerColumnModifications = (answerId, interpretationId) =>
  post(`/answers/column-modifications/${answerId}/${interpretationId}?sessionId=${window.veezoo.sessionId}`);

const getFilterTemplates = (answerId, interpretationId, candidateId, representedRelation) =>
  post('/answers/filter-templates', {
    answerId,
    interpretationId,
    sessionId: window.veezoo.sessionId,
    candidateId,
    representedRelation
  });

const downloadAnswerAsExcel = exportOptions => {
  const body = {
    exportOptions,
    fileType: 'xlsx',
    sessionId: window.veezoo.sessionId
  };
  return postWithBlobResponse(`/answers/partials/export`, body);
};

export default {
  getTableAnswerColumnModifications,
  getFilterTemplates,
  downloadAnswerAsExcel
};
