import React from 'react';
import PropTypes from 'prop-types';

import UIStepper from '@mui/material/Stepper';
import UIStep from '@mui/material/Step';
import UIStepLabel from '@mui/material/StepLabel';
import UIStepConnector from '@mui/material/StepConnector';

import { useConnectorStyles, useIconStyles, useStepperStyles } from './Stepper.styles';

import useMediaQuery from 'utils/mediaQueries';

const StepIcon = props => {
  const isMobile = useMediaQuery();
  const { classes, cx } = useIconStyles({ isMobile });
  const { active, completed, icon } = props;

  const colorScheme = active ? 'active' : completed ? 'completed' : 'circle';

  return (
    <div className={classes.root}>
      <div className={cx(classes.base, classes[colorScheme])}>{icon}</div>
    </div>
  );
};

const Stepper = ({ step = 1, totalSteps = 4 }) => {
  const isMobile = useMediaQuery();
  const connectorStyles = useConnectorStyles({ isMobile });
  const stepperClasses = useStepperStyles();
  return (
    <UIStepper
      alternativeLabel
      sx={stepperClasses}
      activeStep={step - 1}
      connector={<UIStepConnector sx={connectorStyles} />}
    >
      {Array.from({ length: totalSteps }).map((_, index) => (
        <UIStep key={`step_${index}`}>
          <UIStepLabel StepIconComponent={StepIcon} />
        </UIStep>
      ))}
    </UIStepper>
  );
};

export default Stepper;

Stepper.propTypes = {
  step: PropTypes.number,
  totalSteps: PropTypes.number
};
