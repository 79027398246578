import React, { memo, useCallback, useState, useEffect, useRef } from 'react';

import { boardFileTypes } from 'store/utils/boardFileTypes';

import { useTranslation } from 'react-i18next';

import FolderIcon from 'svg/v2/folder.svg';
import BoardIcon from 'svg/v2/board_filled.svg';
import { Button, CustomModal, TextField, ToggleButtonList } from 'components/v3';

import { layouts } from 'config/constants';

import useStyles from './styles';

const CreateBoardModal = ({ open, onConfirm, onClose }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const defaultBoardName = t('dashboard-new-board-name');
  const defaultFolderName = t('dashboard-new-folder-name');

  const inputRef = useRef(null);

  const initialFields = {
    name: '',
    type: boardFileTypes.board,
    dirty: false
  };

  const [fields, setFields] = useState(initialFields);

  const resetFields = () => setFields(initialFields);

  const createBoard = useCallback(() => {
    setFields(prev => ({ ...prev, dirty: true }));
    if (!fields.type || !fields.name) {
      return;
    }

    onConfirm({ type: fields.type, name: fields.name });
  }, [fields, onConfirm]);

  const handleInputChange = event => {
    const value = event.target.value;
    setFields(prev => ({ ...prev, name: value }));
  };

  const handleTypeChange = value => {
    if (value === null) return;

    let name = null;
    if (value === boardFileTypes.folder && (fields.name === '' || fields.name === defaultBoardName)) {
      name = defaultFolderName;
    }
    if (value === boardFileTypes.board && (fields.name === '' || fields.name === defaultFolderName)) {
      name = defaultBoardName;
    }

    setFields(prev => ({ type: value, name: name || prev.name }));
  };

  const onKeyDown = useCallback(
    event => {
      if (event.key === 'Enter') {
        // preventDefault necessary, otherwise the <button /> "+" icon that opens the modal would
        // re-trigger by default, causing the modal to re-open endlessly.
        // TODO: This doesn't seem to be necessary on React 18. We should remove this when we upgrade.
        event.preventDefault();
        createBoard();
      }
    },
    [createBoard]
  );

  const handleFocus = event => event.target.select();

  // We set the translated form of "New board" because, when opening veezoo, the default language is always "EN" and then
  // after fetching User info from the backend, we know the default language for this KG.
  // Setting the translated form directly into "initialState" doesn't update when the new language is fetched,
  // so we have to set it here through "setState" (and anywhere in any other case, this is default behavior of React).
  useEffect(() => {
    if (open) {
      setFields(prev => ({
        ...prev,
        name: fields.type === boardFileTypes.folder ? defaultFolderName : defaultBoardName
      }));
    }
  }, [open]);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, [fields]);

  const options = [
    {
      label: t('board.board'),
      value: boardFileTypes.board,
      icon: <BoardIcon />,
      dataTest: 'itemTypeBoardButton'
    },
    {
      label: t('folder'),
      value: boardFileTypes.folder,
      icon: <FolderIcon />,
      dataTest: 'itemTypeFolderButton'
    }
  ];

  return (
    <CustomModal
      layout={layouts.veezoo}
      title={t(fields.type === boardFileTypes.board ? 'dashboard-new-board' : 'dashboard-new-folder')}
      open={open}
      content={
        <>
          <div className={styles.typeContainer}>
            <ToggleButtonList
              direction="row"
              justifyContent="flex-start"
              items={options}
              selectedValue={fields.type}
              setSelectedValue={handleTypeChange}
            />
          </div>
          <div className={styles.inputContainer}>
            <TextField
              layout={layouts.veezoo}
              autoFocus
              label={t('enter-name')}
              value={fields.name}
              ref={inputRef}
              onFocus={handleFocus}
              onChange={handleInputChange}
              onKeyDown={onKeyDown}
              error={fields.dirty && !fields.name}
              data-test="newBoardItemNameInput"
              title={t('enter-name')}
            />
          </div>
          <div className={styles.buttonContainer}>
            <Button layout={layouts.veezoo} mode="dark" fullWidth onClick={createBoard} data-test="newBoardItemConfirm">
              {t('create')}
            </Button>
          </div>
        </>
      }
      onClose={onClose}
      hideButtons
      TransitionProps={{ onExited: resetFields }}
    />
  );
};

export default memo(CreateBoardModal);
