import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Pagination from './Pagination';

export const TranslatedPagination = props => {
  const { t } = props;
  const texts = {
    previous: t('back'),
    next: t('next'),
    page: t('page'),
    of: t('of'),
    rows: t('rows'),
    showing: t('showing'),
    between: t('-'),
    totalRows: t('total-rows')
  };
  return <Pagination {...props} texts={texts} />;
};

TranslatedPagination.propTypes = {
  rowCount: PropTypes.number,
  pages: PropTypes.number,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  PageButtonComponent: PropTypes.any,
  onPageChange: PropTypes.func,
  setPageSize: PropTypes.func,
  showPageSizeOptions: PropTypes.bool,
  canNextPage: PropTypes.bool,
  canPreviousPage: PropTypes.bool,
  showPageJump: PropTypes.bool,
  pageSizeOptions: PropTypes.array
};

export default withTranslation('veezoo')(TranslatedPagination);
