import { tss } from 'tss-react';

const fontFamily = 'var(--font-family)';

const commonStyles = {
  dialogTitle: {
    root: {
      margin: 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  }
};

export const useTssStyles = tss.create({
  closeButton: {
    paddingLeft: 5,
    paddingRight: 5,
    marginRight: -5,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    boxSizing: 'border-box',
    borderWidth: 0,
    fontFamily,
    '& path': { fill: 'var(--primary-color)' },
    '&:hover': {
      backgroundColor: 'var(--lightest-gray)'
    }
  },
  closeIcon: {
    width: 11.5,
    height: 11.5
  }
});

export const useCustomModalStyles = ({
  hideButtons,
  paperStyles,
  manualMaxWidth,
  fullWidth,
  DialogTitlePropsStyles,
  DialogContentPropsStyles,
  DialogActionsPropsStyles
}) => ({
  signup: {
    '& .MuiDialogTitle-root': {
      ...(DialogTitlePropsStyles?.root || {})
    },
    '& .MuiDialogContent-root': {
      ...(DialogContentPropsStyles?.root || {})
    },
    '& .MuiDialogActions-root': {
      ...(DialogActionsPropsStyles?.root || {})
    },
    '& .MuiDialog-paper': {
      ...(fullWidth ? { width: '100%' } : {}),
      ...(manualMaxWidth ? { maxWidth: manualMaxWidth } : {}),
      ...paperStyles
    }
  },
  veezoo: {
    '& .MuiDialogTitle-root': {
      ...commonStyles.dialogTitle.root,
      fontFamily,
      fontWeight: '500 !important',
      fontSize: '16px !important',
      ...(DialogTitlePropsStyles?.root || {})
    },
    '& .MuiDialogContent-root': {
      padding: '8px 24px',
      ...(hideButtons ? { paddingBottom: '24px' } : {}),
      ...(DialogContentPropsStyles?.root || {})
    },
    '& .MuiDialogActions-root': {
      padding: '16px 24px',
      ...(DialogActionsPropsStyles?.root || {})
    },
    '& .MuiDialog-paper': {
      minWidth: 360,
      boxSizing: 'border-box',
      ...(fullWidth ? { width: '100%' } : {}),
      ...(manualMaxWidth ? { maxWidth: manualMaxWidth } : {}),
      ...paperStyles
    }
  },
  default: {
    '& .MuiDialogTitle-root': {
      ...commonStyles.dialogTitle.root,
      padding: '16px 24px',
      fontSize: '1.25rem',
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      fontWeight: 500,
      lineHeight: '1.6',
      letterSpacing: '0.0075em',
      flex: '0 0 auto',
      ...(DialogTitlePropsStyles?.root || {})
    },
    '& .MuiDialogContent-root': {
      padding: '8px 24px',
      ...(hideButtons ? { paddingBottom: '16px' } : {}),
      ...(DialogContentPropsStyles?.root || {})
    },
    '& .MuiDialogActions-root': {
      ...(DialogActionsPropsStyles?.root || {})
    },
    '& .MuiDialog-paper': {
      ...(fullWidth ? { width: '100%' } : {}),
      ...(manualMaxWidth ? { maxWidth: manualMaxWidth } : {}),
      ...paperStyles
    }
  }
});
