import { useMemo } from 'react';
import PropTypes from 'prop-types';

import UICheckbox from '@mui/material/Checkbox';

import { useStyles, useCheckboxStyles } from './Checkbox.styles';

import useMediaQuery from 'utils/mediaQueries';

import { layouts } from 'config/constants';

const Checkbox = ({
  label = '',
  checked = false,
  onChange = () => {},
  content = '',
  layout = layouts.default,
  disabled = false,
  size = 'medium',
  classes: customClasses = {},
  'data-test': dataTest,
  ...props
}) => {
  const isMobile = useMediaQuery();

  const { classes } = useStyles({ isMobile, size, disabled, layout, customClasses });
  const checkboxClasses = useCheckboxStyles({ isMobile, size });

  const handleChange = () => !disabled && onChange(!checked);

  const formattedContent = useMemo(() => {
    if (typeof content === 'string') {
      return <span className={classes.labelFont}>{content}</span>;
    }
    return content;
  }, [content, disabled]);

  return (
    <div className={classes.container} onClick={handleChange} {...(dataTest ? { 'data-test': dataTest } : {})}>
      <UICheckbox
        sx={checkboxClasses[layout]}
        color="primary"
        checked={!!checked}
        onChange={handleChange}
        disabled={disabled}
        {...props}
      />
      {formattedContent}
    </div>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  content: PropTypes.any,
  disabled: PropTypes.bool,
  layout: PropTypes.string,
  classes: PropTypes.object,
  'data-test': PropTypes.string,
  size: PropTypes.string
};
