import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DoneAllIcon from '@mui/icons-material/DoneAll';
import { makeStyles } from '@material-ui/core/styles';

import { Button, Stepper } from 'components/v3';

import { setFinishedSignup } from 'store/modules/user';
import { fetchKnowledgeGraphs } from 'store/modules/knowledgeGraph';

import useMediaQuery from 'utils/mediaQueries';

import { layouts } from 'config/constants';

import styles from '../ImportTables/index.module.scss';
import signupStyles from 'pages/Signup/signup.styles.scss';
import StepperContainer from 'components/Signup/StepperContainer/StepperContainer';

const useStyles = makeStyles({
  root: {
    width: ({ isMobile }) => (isMobile ? 60 : 80),
    height: ({ isMobile }) => (isMobile ? 60 : 80),
    color: 'var(--light-blue)'
  }
});

const Completed = ({ dispatch }) => {
  const isMobile = useMediaQuery();
  const iconClasses = useStyles({ isMobile });
  const history = useHistory();

  useEffect(() => {
    dispatch(setFinishedSignup());
    dispatch(fetchKnowledgeGraphs());
  }, []);

  return (
    <StepperContainer
      customContainerStyle={signupStyles.customContainer}
      customStepper={<Stepper step={5} totalSteps={5} />}
    >
      <div className={signupStyles.centerChildHorizontallyWithoutTitle} style={{ alignItems: 'center' }}>
        <div className={styles.container}>
          <div className={styles.iconContainer}>
            <DoneAllIcon classes={iconClasses} />
          </div>
          <div className={styles.header}>You&apos;re all set!!</div>
          <div className={styles.description}>
            We have finished your registration, and your first Knowledge Graph is ready to use!
            <br />
            Click the button below to start asking question, and welcome to Veezoo!
          </div>
          <div className={styles.centerButtonContainer}>
            <Button
              layout={layouts.signup}
              onClick={() => history.push('/chat')}
              size="large"
              mode="dark"
              data-test="startAskingQuestionsButton"
            >
              Start Asking Questions!
            </Button>
          </div>
        </div>
      </div>
    </StepperContainer>
  );
};

export default connect()(Completed);
