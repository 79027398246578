import { fetchUserInformation } from './user';
import { changeBoardNamePromise, MAKE_INDEPENDENT_SUCCESS, publishBoardChanges } from 'store/modules/board';

import { handleError } from 'services/http';

import services from 'services';

export const BOARD_META_WIDGETS_REQUEST = 'BOARD_META_WIDGETS_REQUEST';
export const BOARD_META_WIDGETS_SUCCESS = 'BOARD_META_WIDGETS_SUCCESS';
export const BOARD_META_WIDGETS_FAILURE = 'BOARD_META_WIDGETS_FAILURE';

export const BOARDWIDGET_REQUEST = 'BOARDWIDGET_REQUEST';
export const BOARD_WIDGET_SUCCESS = 'BOARD_WIDGET_SUCCESS';
export const BOARD_WIDGET_FAILURE = 'BOARD_WIDGET_FAILURE';

export const ADD_WIDGET_TO_BOARD_REQUEST = 'ADD_WIDGET_TO_BOARD_REQUEST';
export const ADD_WIDGET_TO_BOARD_SUCCESS = 'ADD_WIDGET_TO_BOARD_SUCCESS';
export const ADD_WIDGET_TO_BOARD_FAILURE = 'ADD_WIDGET_TO_BOARD_FAILURE';

export const UPDATE_BOARD_WIDGETS_REQUEST = 'UPDATE_BOARD_WIDGETS_REQUEST';
export const UPDATE_BOARDWIDGETS_SUCCESS = 'UPDATE_BOARDWIDGETS_SUCCESS';
export const UPDATE_BOARDWIDGETS_FAILURE = 'UPDATE_BOARDWIDGETS_FAILURE';

/**
 * Cancels a widget request
 */
export const cancelWidget = widgetId => {
  return async dispatch => {
    const result = await services.cancelWidget(widgetId);
    handleError(result, dispatch);
  };
};

/**
 * Fetches content of a Widget
 */
export const fetchWidget = (id, boardId) => {
  return async dispatch => {
    dispatch({ type: BOARDWIDGET_REQUEST, boardId });

    const result = await services.fetchWidgetById(id);
    handleError(result, dispatch);

    if (!result.success) {
      const errorMessage = result?.response?.data || 'There was an error fetching widget';
      dispatch({
        type: BOARD_WIDGET_FAILURE,
        id,
        boardId,
        errorMessage,
        isUnauthorized: result.status === 403
      });
      return;
    }

    dispatch({
      type: BOARD_WIDGET_SUCCESS,
      data: { ...result.data, boardId }
    });
  };
};

/**
 * Fetches meta widgets of a board.
 *
 * For meta-widgets which do not have their ids in existing WidgetId's.
 * The fetching of the content of the widget will also be dispatched.
 */
export const fetchBoardWidgets = boardId => {
  return async dispatch => {
    dispatch({ type: BOARD_META_WIDGETS_REQUEST, boardId });

    const result = await services.fetchWidgetsByBoardId(boardId);
    handleError(result, dispatch);

    if (!result.success) {
      dispatch({ type: BOARD_META_WIDGETS_FAILURE });
      return;
    }

    const { linkedToBoard, widgets, baseBoardChanges } = result.data;
    dispatch(updateMetaWidgetsOfBoard(boardId, linkedToBoard, widgets, baseBoardChanges));
  };
};

export function updateMetaWidgetsOfBoard(boardId, linkedToBoard, widgets, baseBoardChanges) {
  return (dispatch, getState) => {
    const boardWidgets = widgets.map(w => ({ ...w, boardId }));
    dispatch({
      type: BOARD_META_WIDGETS_SUCCESS,
      boardId,
      metaWidgets: boardWidgets,
      linkedToBoard,
      baseBoardChanges
    });

    getState().boardWidgets.forEach(boardWidget => {
      if (boardWidget.boardId === boardId) {
        boardWidget.widgets?.forEach(widget => {
          if (!widget.answer) {
            dispatch(fetchWidget(widget.id, boardId));
          }
        });
      }
    });
  };
}

/**
 * Adds a widget to a dashboard and dispatches a fetch directly after adding to get it.
 */
export const addWidgetToBoard = (boardId, widget) => {
  return async dispatch => {
    dispatch({ type: ADD_WIDGET_TO_BOARD_REQUEST });

    const result = await services.addWidgetToBoard(boardId, widget);
    handleError(result, dispatch);

    if (!result.success) {
      dispatch({ type: ADD_WIDGET_TO_BOARD_FAILURE });
      return;
    }

    dispatch({
      type: ADD_WIDGET_TO_BOARD_SUCCESS,
      widget: result.data,
      boardId
    });
    // already fetch new widget so the user does not need to wait
    dispatch(fetchWidget(result.data.id, boardId));

    // refresh the user information as adding a widget possibly adds a milestone
    dispatch(fetchUserInformation());
  };
};

/**
 * When updating the board it's important that we do it in the right order as we have various endpoints involved.
 *
 * First we update the widgets (metaWidgets)
 * If the call successfully executes, we optionally, update the board name (updatedBoardName) which could be undefined
 * to avoid an unnecessary call to the change board name end point.
 *
 * Only after also this optional endpoint returned and the publish flag (saveAndPublish) was passed,
 * we actually publish. If we'd do this in parallel there can and will be race conditions.
 */
export const updateBoard = (boardId, metaWidgets, updatedBoardName, saveAndPublish = false) => {
  return async dispatch => {
    dispatch({
      boardId,
      metaWidgets,
      type: UPDATE_BOARD_WIDGETS_REQUEST
    });

    const result = await services.updateWidgetsOfBoard(boardId, metaWidgets);
    handleError(result, dispatch);

    if (!result.success) {
      dispatch({ type: UPDATE_BOARDWIDGETS_FAILURE });
      return;
    }

    // widgets were successfully updated, now we can optionally update the board name as well
    if (updatedBoardName) {
      changeBoardNamePromise(boardId, updatedBoardName, dispatch, saveAndPublish);
    }

    dispatch({
      boardId,
      type: UPDATE_BOARDWIDGETS_SUCCESS,
      willPublishBoard: saveAndPublish
    });

    // After it's successfully saved as well, finally publish the board
    if (saveAndPublish) {
      dispatch(publishBoardChanges(boardId));
    }
  };
};

// Update the "board widget properties" of the given board
const updateBoardWidgetProperties = (boardWidgetProperties, boardId, properties) => {
  if (!boardId) {
    throw new Error('boardId is required');
  }

  function updateProperties(widgetProperties) {
    let updatedProperties = typeof properties === 'function' ? properties(widgetProperties) : properties;
    if (Object.keys(updatedProperties).some(key => !(key in widgetProperties))) {
      console.error('Invalid properties to update board widget properties', updatedProperties);
    }
    if (updatedProperties?.widgets?.some(w => !w)) {
      console.error('Invalid widget properties', updatedProperties.widgets);
    }
    return { ...widgetProperties, ...updatedProperties };
  }

  const boardIndex = boardWidgetProperties.findIndex(properties => properties.boardId === boardId);

  if (boardIndex === -1) {
    // Initialize the board widget properties
    const initialBoardWidgetProperties = {
      boardId,
      widgets: [],
      isLoadingMetaWidgets: false,
      isFinishedLoadingMetaWidgets: false,
      linkedToBoard: undefined
    };
    return [...boardWidgetProperties, updateProperties(initialBoardWidgetProperties)];
  }

  return boardWidgetProperties.map(widgetProperties => {
    if (widgetProperties.boardId === boardId) {
      // if the properties are a function, we call it with the current properties
      return updateProperties(widgetProperties);
    } else {
      return widgetProperties;
    }
  });
};

// This reducer holds all the requested board widgets, and some information coming from the widget endpoint (linkedToBoard)
// Each item of the array corresponds to a board and works as a "cache" for the already requested widgets. It contains the following properties:
// { boardId: ..., widgets: ..., isLoadingMetaWidgets: ..., isFinishedLoadingMetaWidgets: ..., linkedToBoard: ... }
// The "widgets" property is an array of the widgets of the board, and contain the widget meta information as well as a cached answer
export function boardWidgets(state = [], action) {
  switch (action.type) {
    case BOARD_META_WIDGETS_REQUEST:
      return updateBoardWidgetProperties(state, action.boardId, {
        isLoadingMetaWidgets: true
      });

    case BOARD_META_WIDGETS_SUCCESS: {
      return updateBoardWidgetProperties(state, action.boardId, properties => {
        const newWidgets = action.metaWidgets.map(newWidget => {
          // Reuse answer for same interpretation from any widget in any board
          const sameInterpretationWidget = state
            .flatMap(p => p.widgets)
            .find(
              stateWidget =>
                stateWidget.interpretationId === newWidget.interpretationId && properties.widgets.widget?.answer
            );

          return sameInterpretationWidget ? { ...newWidget, answer: sameInterpretationWidget.answer } : newWidget;
        });

        return {
          isLoadingMetaWidgets: false,
          isFinishedLoadingMetaWidgets: true,
          linkedToBoard: action.linkedToBoard,
          widgets: newWidgets
        };
      });
    }

    case BOARD_WIDGET_SUCCESS: {
      return updateBoardWidgetProperties(state, action.data.boardId, ({ widgets }) => {
        const indexToReplace = widgets.findIndex(widget => widget.id === action.data.metaWidget.id);
        if (indexToReplace !== -1) {
          // If there is a meta widget, replace it
          let newWidgets = [...widgets];
          newWidgets[indexToReplace] = {
            ...action.data.metaWidget,
            boardId: action.data.boardId,
            answer: action.data.answer,
            // VZN-9971 it's possible to enter this case when for instance a query times out
            // for other error messages we usually enter the BOARDWIDGET_FAILURE case
            ...(action.data.answer?.isError && {
              hasFailed: true,
              errorMessage: action.data.answer.textAnswer
            })
          };
          return { widgets: newWidgets };
        } else {
          // This could happen when for instance a loading widget / meta widget is deleted
          return {};
        }
      });
    }

    case BOARD_WIDGET_FAILURE: {
      return updateBoardWidgetProperties(state, action.boardId, ({ widgets }) => {
        const indexToReplace = widgets.findIndex(widget => widget.id === action.id);
        if (indexToReplace !== -1) {
          // If there is a meta widget, replace it
          let newWidgets = [...widgets];
          newWidgets[indexToReplace] = {
            ...newWidgets[indexToReplace],
            hasFailed: true,
            errorMessage: action.errorMessage,
            isUnauthorized: action.isUnauthorized
          };
          return { widgets: newWidgets };
        } else {
          // This could happen when for instance a loading widget / meta widget is deleted
          return {};
        }
      });
    }

    case UPDATE_BOARD_WIDGETS_REQUEST: {
      // Add new / updated widgets to state
      // Make sure that the full answers from the state are added to the new widgets
      return updateBoardWidgetProperties(state, action.boardId, ({ widgets }) => {
        const updatedWidgets = action.metaWidgets.map(widget => {
          return { ...widget, answer: widgets.find(w => w.id === widget.id)?.answer };
        });
        return { widgets: updatedWidgets };
      });
    }

    case ADD_WIDGET_TO_BOARD_SUCCESS:
      return updateBoardWidgetProperties(state, action.boardId, ({ widgets }) => {
        return { widgets: [...widgets, { ...action.widget, boardId: action.boardId }] };
      });

    case MAKE_INDEPENDENT_SUCCESS: {
      return updateBoardWidgetProperties(state, action.boardId, { linkedToBoard: undefined });
    }

    default:
      return state;
  }
}
