import { useState } from 'react';
import { withTranslation } from 'react-i18next';

import Button from 'components/buttons/Button';
import LoadingIcon from 'components/loaders/LoadingIcon';

import { InfoTooltip } from 'components/v3';

import useMediaQuery from 'utils/mediaQueries';

import downloadFileBlob from 'utils/downloadFileBlob';
import services from 'services';
import { trackEvent } from '../../utils/eventTracking';
import PropTypes from 'prop-types';

/**
 * DownloadButton Component
 *
 * @param {Array} props.partialAnswerOptions - Array of objects each containing partialAnswerId, visualizationType, visualizationOptions
 */
const DownloadButton = ({ partialAnswerOptions, question, t }) => {
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery();

  const downloadFile = async exportOptions => {
    setLoading(true);
    try {
      const result = await services.downloadAnswerAsExcel(exportOptions, 'xlsx');
      if (result) {
        // Assuming result is a blob
        downloadFileBlob(result, 'export.xlsx');
        trackEvent('Download Excel clicked', { question, exportOptions });
      } else {
        console.error('Download failed: No data received.');
      }
    } catch (error) {
      console.error('Download failed:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <InfoTooltip text={t('tooltip.download')}>
      <Button
        className="button-secondary"
        text={t('answer-message.export-xls')}
        disabled={loading}
        showOnlyIcon={isMobile}
        onClick={() => downloadFile(partialAnswerOptions)}
      >
        {loading ? <LoadingIcon /> : <span className="icon-excel" />}
      </Button>
    </InfoTooltip>
  );
};

DownloadButton.propTypes = {
  partialAnswerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      partialAnswerId: PropTypes.string.isRequired,
      visualizationType: PropTypes.string,
      visualizationOptions: PropTypes.object
    })
  ).isRequired,
  question: PropTypes.string,
  t: PropTypes.func.isRequired
};

export default withTranslation('veezoo')(DownloadButton);
