import { Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import styles from './answer-message-footer.scss';
import FollowUpButton from '../buttons/FollowUpButton';
import SaveBoardButton from '../buttons/SaveBoardButton';
import PrintButton from '../buttons/PrintButton';
import DownloadButton from 'components/buttons/DownloadButton';

function MultiAnswerMessageFooter(props) {
  const { answerId, messageId, metaPartialAnswers, targetRef } = props;

  const followUpButton = (
    <li className={styles.item}>
      <FollowUpButton
        interpretationId={undefined}
        partialAnswerId={undefined}
        answerId={answerId}
        messageId={messageId}
        followUpState={props.followUpState}
        dispatch={props.dispatch}
      />
    </li>
  );
  const saveBoardButton = (
    <li className={styles.item}>
      <SaveBoardButton answerId={answerId} metaPartialAnswers={metaPartialAnswers} dispatch={props.dispatch} />
    </li>
  );
  const printButton = (
    <li className={styles.item}>
      <PrintButton targetRef={targetRef} />
    </li>
  );

  const downloadButton = metaPartialAnswers?.length > 0 && (
    <li className={styles.item}>
      <DownloadButton
        partialAnswerOptions={metaPartialAnswers.map(p => ({
          partialAnswerId: p.partialAnswerId,
          visualizationType: p.type,
          visualizationOptions: p.visualizationOptions
        }))}
      />
    </li>
  );

  return (
    <Fragment>
      <div className={styles.buttonsContainer}>
        <ul className={styles.list}>
          {followUpButton}
          {saveBoardButton}
          {downloadButton}
          {printButton}
        </ul>
      </div>
    </Fragment>
  );
}

export const getMetaPartialAnswers = (state, messageId) => {
  return state.chatMessages.find(m => m.id === messageId)?.metaPartialAnswers;
};
const mapStateToProps = (state, ownProps) => {
  return {
    followUpState: state.followUpState,
    metaPartialAnswers: getMetaPartialAnswers(state, ownProps.messageId)
  };
};

export default withTranslation('veezoo')(connect(mapStateToProps)(MultiAnswerMessageFooter));
