import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';

import Avatar from 'components/avatar/Avatar';
import OldButton from 'components/buttons/Button';
import Button from 'components/v3/Button/Button';

import { CustomModal, Select } from 'components/v3';

import { fetchNumberFormats } from 'store/modules/localization';
import { changePassword, clearPasswordChangeState, fetchUserInformation } from 'store/modules/user';
import { signout } from 'store/modules/network';

import services from 'services';
import { formatNumber } from 'utils/numberFormat';
import { layouts } from 'config/constants';

import styles from './settings.scss';

// the sample number to use for number format examples
const sampleNumber = 1234.56789;

const initialState = {
  showModal: false,
  passwordChangeStarted: false,
  oldPassword: '',
  newPassword: ''
};

const Settings = ({
  isSingleSignOn,
  isChangingPassword,
  passwordChangeTried,
  passwordChangeSucceeded,
  user,
  numberFormats,
  enqueueSnackbar,
  dispatch
}) => {
  const [state, setState] = useState(initialState);

  const { t } = useTranslation();
  const oldPasswordInputRef = useRef(null);

  const numberFormatOptions = useMemo(
    () => [
      {
        label: `Default`,
        value: '-1'
      },
      ...numberFormats.map((numberFormat, index) => ({
        label: formatNumber(sampleNumber, numberFormat),
        value: `${index}`
      }))
    ],
    [numberFormats]
  );

  const startPasswordChange = () => setState(prev => ({ ...prev, passwordChangeStarted: true }));

  const handlePasswordChange = event => {
    event.preventDefault();
    setState(prev => ({ ...prev, oldPassword: '', newPassword: '' }));

    dispatch(changePassword(state.oldPassword, state.newPassword));
  };

  const exitPasswordChange = event => {
    event.preventDefault();
    dispatch(clearPasswordChangeState());
    setState(prev => ({ ...prev, passwordChangeStarted: false }));
  };

  const handleOldPasswordInput = event => {
    const oldPassword = event.target.value;
    setState(prev => ({ ...prev, oldPassword }));
  };

  const handleNewPasswordInput = event => {
    const newPassword = event.target.value;
    setState(prev => ({ ...prev, newPassword }));
  };

  const handleNumberFormatChange = async event => {
    const update = {
      // set it as 'null' if the default is selected
      preferredNumberFormat: numberFormats[event.target.value]?.data || null
    };
    const result = await services.updateUserSettings(update);
    if (result.success) {
      dispatch(fetchUserInformation());
      enqueueSnackbar(`Reload the page for the new number format to take effect`, {
        variant: 'info',
        autoHideDuration: 3000
      });
    }
  };

  const showModal = () => setState(prev => ({ ...prev, showModal: true }));
  const closeModal = () => setState(prev => ({ ...prev, showModal: false }));
  const doSignout = () => dispatch(signout());

  useEffect(() => {
    dispatch(fetchNumberFormats());
  }, [dispatch]);

  const exitPasswordChangeButton = (
    <Button layout={layouts.veezoo} size="small" onClick={exitPasswordChange}>
      {t('exit')}
    </Button>
  );

  const passwordChangeForm = (
    <form className={styles.form} onSubmit={handlePasswordChange}>
      <input
        ref={input => {
          oldPasswordInputRef.current = input;
        }}
        className={styles.input}
        type="password"
        placeholder={t('old-password')}
        value={state.oldPassword}
        onChange={handleOldPasswordInput}
      />
      <input
        className={styles.input}
        type="password"
        placeholder={t('new-password')}
        value={state.newPassword}
        onChange={handleNewPasswordInput}
      />

      <div className={styles.ChangeButtonContainer}>
        <Button layout={layouts.veezoo} size="small" type="submit" disabled={isChangingPassword}>
          {t('change')}
        </Button>
        {exitPasswordChangeButton}
      </div>
    </form>
  );

  const triedAndFailed = passwordChangeTried && !passwordChangeSucceeded;
  const triedAndSucceeded = passwordChangeTried && passwordChangeSucceeded;

  // the user's preferred number format (if any)
  const preferredNumberFormat = numberFormats.find(
    numberFormat =>
      numberFormat.data.locale === user.preferredNumberFormat?.data.locale &&
      numberFormat.data.pattern === user.preferredNumberFormat?.data.pattern
  );

  return (
    <div className={styles.container}>
      <div className={styles.userMessage}>
        <Avatar size={50} />

        <div className={styles.user}>
          <h2 className={styles.userName}>{t('user-profile', { userName: user.username })}</h2>
          {!isSingleSignOn && (
            <OldButton text={t('sign-out')} data-test="signOut" className="link" onClick={showModal} />
          )}
        </div>
      </div>

      {!isSingleSignOn && (
        <div className={styles.section}>
          <h3 className={styles.subTitle}>{t('general')}</h3>
          {!passwordChangeTried && state.passwordChangeStarted && passwordChangeForm}
          {triedAndSucceeded && (
            <div className={styles.successMessageContainer}>
              <p className={styles.successMessage}>{t('successful-password-change')}</p>
              {exitPasswordChangeButton}
            </div>
          )}
          {triedAndFailed && (
            <div className={styles.failureMessageContainer}>
              <div className={styles.failureMessage}>{t('wrong-or-insufficient-password')}</div>
              {passwordChangeForm}
            </div>
          )}
          {!state.passwordChangeStarted && (
            <Button layout={layouts.veezoo} size="small" onClick={startPasswordChange}>
              {t('change-password')}
            </Button>
          )}
        </div>
      )}

      <div className={styles.section}>
        <h3 className={styles.subTitle}>Appearance</h3>
        <div className={styles.setting}>
          <h4>Preferred number format</h4>
          <p>Set the preferred number format to use.</p>
          <div className={styles.settingInput}>
            <Select
              layout={layouts.veezoo}
              value={preferredNumberFormat ? `${numberFormats.indexOf(preferredNumberFormat)}` : '-1'}
              onChange={handleNumberFormatChange}
              options={numberFormatOptions}
            />
          </div>
        </div>
      </div>

      <CustomModal
        layout={layouts.veezoo}
        open={state.showModal}
        hideCloseIcon
        content={
          <>
            <span className={styles.signOutText}>{t('sign-out-prompt')}</span>
            <div className={styles.signOutButtonContainer}>
              <Button layout={layouts.veezoo} size="small" onClick={closeModal} data-test="cancelSignOut">
                {t('no')}
              </Button>
              <Button layout={layouts.veezoo} size="small" mode="dark" onClick={doSignout} data-test="doSignOut">
                {t('yes')}
              </Button>
            </div>
          </>
        }
        onClose={closeModal}
        hideButtons
      />
    </div>
  );
};

const mapStateToProps = state => ({
  avatar: state.user.avatar,
  passwordChangeTried: state.user.passwordChangeTried,
  passwordChangeSucceeded: state.user.passwordChangeSucceeded,
  isChangingPassword: state.network.isChangingPassword,
  isSingleSignOn: state.user.isSingleSignOn,
  user: state.user,
  numberFormats: state.localization.numberFormats
});

export default withSnackbar(connect(mapStateToProps)(Settings));
